import axios from "./api";

let clySA = `/sa/`;
if (process.env.NODE_ENV == "production") { // 生产环境
    clySA = `https://api.gradgroup.cn/sa/`;
}
else if (process.env.NODE_ENV == "development") {// 开发环境
    // clySA = `/sa/`;
    clySA = `https://api.gradgroup.cn/sa/`;
}

//班次管理
export const GetShiftGroupPageList = (params) => {
  return axios.get(clySA + `ShiftGroup/GetShiftGroupPageList`, {
    params: params,
  });
};
export const ShiftGroupGetShiftGroup = (params) => {
  return axios.get(clySA + `ShiftGroup/GetShiftGroup`, { params: params });
};

export const AddShiftGroup = (params) => {
  return axios.post(clySA + `ShiftGroup/AddShiftGroup`, params);
};
export const DeleteShiftGroup = (params) => {
  return axios.delete(clySA + `ShiftGroup/DeleteShiftGroup`, {
    params: params,
  });
};
export const UpdateShiftGroup = (params) => {
  return axios.put(clySA + `ShiftGroup/UpdateShiftGroup`, params);
};
//考勤组
export const GetAttendGroupPageList = (params) => {
  return axios.get(clySA + `AttendGroup/GetAttendGroupPageList`, {
    params: params,
  });
};
export const GetAllAttendGroup = (params) => {
  return axios.get(clySA + `AttendGroup/GetAllAttendGroup`, {
    params: params,
  });
};
export const AddAttendGroup = (params) => {
  return axios.post(clySA + `AttendGroup/AddAttendGroup`, params);
};
export const DeleteAttendGroup = (params) => {
  return axios.delete(clySA + `AttendGroup/DeleteAttendGroup`, {
    params: params,
  });
};
export const UpdateAttendGroup = (params) => {
  return axios.put(clySA + `AttendGroup/UpdateAttendGroup`, params);
};

export const AttendGroupGetAttendGroupById = (params) => {
  return axios.get(clySA + `AttendGroup/GetAttendGroupById`, {
    params: params,
  });
};
export const SetAttendLock = (params) => {
  return axios.put(clySA + `UserSchedule/SetAttendLock?attendGroupId=` + params.attendGroupId + `&setDate=` + params.setDate + `&lockType=` + params.lockType);
  // return axios.put(clySA + `UserSchedule/SetAttendLock`, params);
};
export const AttenUserSchedIsLocked = (params) => {
  return axios.get(clySA + `UserSchedule/IsLocked`, {
    params: params,
  });
};

//获取排班
export const UserScheduleGetUserSchedule = (params) => {
  return axios.get(clySA + `UserSchedule/GetUserScheduleData`, {
    params: params,
  });
};
export const UserScheduleGetScheduleHeader = (params) => {
  return axios.get(clySA + `UserSchedule/GetScheduleHeader`, {
    params: params,
  });
};

//考勤记录
export const GetClockInTimePageList = (params) => {
  return axios.get(clySA + `ClockIn/GetClockInTimePageList`, {
    params: params,
  });
};
//考勤月度统计
export const ClockInGetMonthly = (params) => {
  return axios.get(clySA + `ClockIn/GetMonthlySummaryPageList`, {
    params: params,
  });
};
//根据考勤id获取所属班次
export const GetShiftGroupByAttendGroupIds = (params) => {
  return axios.get(clySA + `ShiftGroup/GetShiftGroupByAttendGroupIds`, {
    params: params,
  });
};
//批量设置当前日期所有班次
export const SetScheduleByDate = (params) => {
  return axios.get(clySA + `UserSchedule/SetScheduleByDate`, {
    params: params,
  });
};
export const SetScheduleByUser = (params) => {
  return axios.get(clySA + `UserSchedule/SetScheduleByUser`, {
    params: params,
  });
};
//更新排班
export const SaveUserSchedule = (params) => {
  return axios.post(clySA + `UserSchedule/SaveUserSchedule`, params);
};

export const SetScheduleByMonth = (params) => {
  return axios.get(clySA + `UserSchedule/SetScheduleByMonth`, {
    params: params,
  });
};
//重算考勤结果
export const ActiveChongSuan = (params) => {
  return axios.get(clySA + `UserSchedule/ActiveChongSuan`, {
    params: params,
  });
};
//每日统计
export const GetDailyStatisticsPageList = (params) => {
  return axios.get(clySA + `ClockIn/GetDailyStatisticsPageList`, {
    params: params,
  });
};
//每日打卡记录
export const GetUserClockInTimePageList = (params) => {
  return axios.get(clySA + `ClockIn/GetClockInTimePageList`, {
    params: params,
  });
};
//出差记录
export const GetBusinessTripList = (params) => {
  return axios.get(clySA + `ClockIn/GetBusinessTripList`, { params: params });
};
//加班记录
export const GetWorkOvertimeList = (params) => {
  return axios.get(clySA + `ClockIn/GetWorkOvertimeList`, { params: params });
};
//请假记录
export const GetLeaveList = (params) => {
  return axios.get(clySA + `ClockIn/GetLeaveList`, { params: params });
};
//小时假
export const GetHoursLeaveList = (params) => {
  return axios.get(clySA + `ClockIn/GetHoursLeaveList`, { params: params });
};
//探亲假
export const GetVisitFamilyList = (params) => {
  return axios.get(clySA + `ClockIn/GetVisitFamilyList`, { params: params });
};
//返回公司
export const GetReturnCompanyList = (params) => {
  return axios.get(clySA + `ClockIn/GetReturnCompanyList`, { params: params });
};

//区域管理相关
export const GetAreaPageList = (params) => {
  return axios.get(clySA + `AttendGroupArea/GetAreaPageList`, { params: params });
};
export const GetAreaById = (params) => {
  return axios.get(clySA + `AttendGroupArea/GetAreaById`, { params: params });
};
export const AddAttendGroupArea = (params) => {
  return axios.post(clySA + `AttendGroupArea/AddAttendGroupArea`, params);
};
export const DeleteAttendGroupArea = (params) => {
  return axios.delete(clySA + `AttendGroupArea/DeleteAttendGroupArea`, {
    params: params,
  });
};
export const UpdateAreaAttendGroup = (params) => {
  return axios.put(clySA + `AttendGroupArea/UpdateAttendGroup`, params);
};

//清除用户缓存
export const RemoveAllUserInfoCache = (params) => {
  return axios.delete(clySA + `AttendGroup/RemoveAllUserInfoCache`, { params: params });
};