import axios from "./api";

//任务通用分类模块管理
export const GetClassifyList = params => {
  return axios.get(`/wt/api/TaskCommonClassify/GetClassifyList`, { params: params });
};
export const QueryAllClassifyList = params => {
  return axios.get(`/wt/api/TaskCommonClassify/QueryAllClassifyList`, { params: params });
}
export const AddTaskCommonClassify = params => {
  return axios.post(`/wt/api/TaskCommonClassify/AddTaskCommonClassify`, params);
};
export const DeleteTaskCommonClassify = params => {
  return axios.post(`/wt/api/TaskCommonClassify/SetTaskCommonClassifyEnableStatus?id=` + params.id + `&enabled=` + params.enabled);
};
export const UpdateTaskCommonClassify = params => {
  return axios.put(`/wt/api/TaskCommonClassify/UpdateTaskCommonClassify?id=` + params.Id + `&displayOrder=` + params.DisplayOrder);
};
//任务类型配置模块管理
export const QueryAllClassifyConfigList = params => {
  return axios.get(`/wt/api/TaskCommonClassifyConfig/QueryAllClassifyConfigList`, { params: params });
};
export const SetCommonClassifyConfig = params => {
  return axios.post(`/wt/api/TaskCommonClassifyConfig/SetCommonClassifyConfig`, params);
}
//任务模块管理
export const QueryTaskByUserCode = params => {
  return axios.get(`/wt/api/Task/QueryPageTasksByUserCode`, { params: params });
}
export const AddTaskAndAssign = params => {
  return axios.post(`/wt/api/Task/AddTaskAndAssign`, params);
};
export const AddTask = params => {
  return axios.post(`/wt/api/Task/AddTask`, params);
};
export const AddTask20210524 = params => {
  return axios.post(`/wt/api/Task/AddTask20210524`, params);
};
export const DeleteTaskById = params => {
  return axios.delete(`/wt/api/Task/DeleteTaskById`, { params: params });
};
export const UpdateTaskContent = params => {
  return axios.put(`/wt/api/Task/UpdateTaskContent`, params);
};
export const AssignTask = params => {
  return axios.post(`/wt/api/Task/AssignTask`, params);
};
export const QueryPageWaitApprovalTasksByUserCode = params => {
  return axios.get(`/wt/api/Task/QueryPageWaitApprovalTasksByUserCode`, { params: params });
};
export const CancelTask = params => {
  return axios.post(`/wt/api/Task/CancelTask?taskId=` + params.taskId + `&wantCancelUserCode=` + params.wantCancelUserCode+ `&wantCancelUserName=` +params.wantCancelUserName);
};
export const ProlongPlanComplateTime = params => {
  return axios.post(`/wt/api/Task/ProlongPlanComplateTime?taskId=` + params.taskId + `&newdate=` + params.newdate);
};
export const CancelTaskForLiudong = params => {
  return axios.post(`/wt/api/Task/CancelTaskForLiudong?taskId=` + params.taskId + `&wantCancelUserCode=` + params.wantCancelUserCode+ `&wantCancelUserName=` +params.wantCancelUserName);
};
export const QueryChildTasksByParentId = params => {
  return axios.get(`/wt/api/Task/QueryChildTasksByParentId`, { params: params });
};
export const QueryPageWaitDoTasksByUserCode = params => {
  return axios.get(`/wt/api/Task/QueryPageWaitDoTasksByUserCode`, { params: params });
};
export const QueryWaitApprovalChildTasksByParentId = params => {
  return axios.get(`/wt/api/Task/QueryWaitApprovalChildTasksByParentId`, { params: params });
};
export const QueryPageComplatedTasksByUserCode = params => {
  return axios.get(`/wt/api/Task/QueryPageComplatedTasksByUserCode`, { params: params });
};
export const QueryPageProcessedTasksByUserCode = params => {
  return axios.get(`/wt/api/Task/QueryPageProcessedTasksByUserCode`, { params: params });
};
export const QueryPageProcessedTasksByUserCode20210130 = params => {
  return axios.get(`/wt/api/Task/QueryPageProcessedTasksByUserCode20210130`, { params: params });
};
export const ActivateTask = params =>{
  return axios.post(`/wt/api/Task/ActivateTask?taskId=` + params.taskId+ `&userCode=` +params.userCode+ `&userName=` +params.userName);
};
export const GetMyUpLeader = params => {
  return axios.get(`/wt/api/Task/GetMyUpLeader?userCode=` + params.userCode );
};
export const QueryTasksById = params => {
  return axios.get(`/wt/api/Task/QueryTasksById`, { params: params });
};
export const ReSubmitApproval = params => {
  return axios.post(`/wt/api/Task/ReSubmitApproval?taskId=`+ params.taskId + `&taskClassify=` +params.taskClassify+ `&taskParentId=` +params.taskParentId);
};
export const QueryTaskExtendDataByTaskId = params => {
  return axios.get(`/wt/api/Task/QueryTaskExtendDataByTaskId`, { params: params });
};
export const QueryPageWaitReadTasksByUserCode = params => {
  return axios.get(`/wt/api/Task/QueryPageWaitReadTasksByUserCode`, { params: params });
};
export const QueryPageWaitReadTasksByUserCode201228 = params => {
  return axios.get(`/wt/api/Task/QueryPageWaitReadTasksByUserCode201228`, { params: params });
};
export const QueryPageTasksByWhere = params => {
  return axios.get(`/wt/api/Task/QueryPageTasksByWhere`, { params: params });
};
export const QueryPageYearplanTasksByWhere = params => {
  return axios.get(`/wt/api/Task/QueryPageYearplanTasksByWhere`, { params: params });
};
export const QueryPageTasksByWhere20210126 = params => {
  return axios.get(`/wt/api/Task/QueryPageTasksByWhere20210126`, { params: params });
};
export const QueryYearPlanAboutTaskByYearplanId = params =>{
  return axios.get(`/wt/api/Task/QueryYearPlanAboutTaskByYearplanId?yearplantaskid=`+params.yearplantaskid+'&taskclassify='+params.taskclassify+'&month='+params.month+'&pageIndex=1&pageSize=500');
}
export const QueryPageTodayOperatorTasksByUsercode = params => {
  return axios.get(`/wt/api/Task/QueryPageTodayOperatorTasksByUsercode`, { params: params });
};
export const QueryPageTodayOperatorProcessByUsercode = params => {
  return axios.get(`/wt/api/GradPlatform/QueryPageTodayOperatorProcessByUsercode`, { params: params });
};
export const TaskReadToComplateById = params => {
  return axios.put(`/wt/api/Task/TaskReadToComplateById?id=`+ params.id);
}
export const SetAllWaitReadTaskReadComplated = params => {
  return axios.post(`/wt/api/Task/SetAllWaitReadTaskReadComplated?usercode=`+params.usercode);
}
export const SetReadComplatedByWhere = params => {
  return axios.post(`/wt/api/Task/SetReadComplatedByWhere?taskId=`+params.taskId+'&readusercode='+params.readusercode+'&notaskType='+params.notaskType);
}
export const GetHomeOverviewNumberData = params => {
  return axios.get(`/wt/api/Task/GetHomeOverviewNumberData`, { params: params });
}
export const GetTaskNumberDataGroupByClassify = params => {
  return axios.get(`/wt/api/Task/GetTaskNumberDataGroupByClassify`, { params: params });
}
export const GetColumnChartDataGroupByClassifyAndWeek = params => {
  return axios.get(`/wt/api/Task/GetColumnChartDataGroupByClassifyAndWeek`, { params: params });
}
export const GetTaskProgressChartData = params => {
  return axios.get(`/wt/api/Task/GetTaskProgressChartData`, { params: params });
}
export const GetPeopleProgressChartData = params => {
  return axios.post(`/wt/api/Task/GetPeopleProgressChartData?year=`+params.year,params.peoples);
}
export const GetPeopleTaskNumberGroupByClassifyChartData = params =>{
  return axios.post(`/wt/api/Task/GetPeopleTaskNumberGroupByClassifyChartData?year=`+params.year,params.peoples);
}
export const GetPeopleTaskNumberByTodayLogData = params =>{
  return axios.post(`/wt/api/Task/GetPeopleTaskNumberByTodayLogData?date=`+params.date,params.peoples);
}
export const QueryPeoplePingfenReport = params =>{
  return axios.post(`/wt/api/TaskPingfen/QueryPeoplePingfenReport?month=`+params.month,params.peoples);
}
export const QueryApprovalPingfenReport = params =>{
  return axios.post(`/wt/api/Task/QueryApprovalPingfenReport?month=`+params.month,params.peoples);
}
export const GetPeopleProcessNumberByTodayLogData = params =>{
  return axios.post(`/wt/api/GradPlatform/GetPeopleProcessNumberByTodayLogData?date=`+params.date,params.peoples);
}
export const GetPeopleComplatedTaskNumberGroupByTimeChartData = params => {
  return axios.post(`/wt/api/Task/GetPeopleComplatedTaskNumberGroupByTimeChartData?year=`+params.year,params.peoples);
}
export const GetEachCompanyUsingTaskNumberGroupByDayChartData = params => {
  return axios.post(`/wt/api/Task/GetEachCompanyUsingTaskNumberGroupByDayChartData?beforeday=`+params.beforeday,params.companylist);
}
export const GetEachPeoplesUsingTaskNumberGroupByDayChartData = params => {
  return axios.post(`/wt/api/Task/GetEachPeoplesUsingTaskNumberGroupByDayChartData?month=`+params.month,params.userlist);
}
export const GetEachPeoplesJixiaoNumberByDayChartData = params => {
  return axios.post(`/wt/api/Task/GetEachPeoplesJixiaoNumberByDayChartData?beginmonth=`+params.beginmonth+'&endmonth='+params.endmonth,params.userlist);
}
//任务进度模块管理
export const AddTaskProgress = params => {
  return axios.post(`/wt/api/TaskProgress/AddTaskProgress`, params);
};
export const QueryTaskProgressByTaskId = params => {
  return axios.get(`/wt/api/TaskProgress/QueryTaskProgressByTaskId`, { params: params });
};
export const UpdateTaskProgress = params => {
  return axios.put(`/wt/api/TaskProgress/UpdateTaskProgress`, params);
};
export const DeleteTaskProgress = params => {
  return axios.delete(`/wt/api/TaskProgress/DeleteTaskProgress`, { params: params });
}
//审核任务模块管理
export const ApproveTaskByTaskId = params => {
  return axios.post(`/wt/api/TaskApproval/ApproveTaskByTaskId`, params);
}
//年度计划模块管理
export const QueryPageYearPlanByUserCode = params => {
  return axios.get(`/wt/api/YearPlan/QueryPageYearPlanByUserCode`, { params: params });
};
export const QueryPageMyImportedYearPlan = params => {
  return axios.get(`/wt/api/YearPlan/QueryPageMyImportedYearPlan`, { params: params });
};
export const AddYearPlan = params => {
  return axios.post(`/wt/api/YearPlan/AddYearPlan`, params);
};
export const QueryYearPlanChildTasksByParentId = params => {
  return axios.get(`/wt/api/YearPlan/QueryChildTasksByParentId`,{ params: params })
};
export const UpdateYearPlanTaskById = params => {
  return axios.put(`/wt/api/YearPlan/UpdateYearPlanTaskById`, params);
};
export const ImportYearPlan = params => {
  return axios.post(`/wt/api/YearPlan/ImportYearPlan`, params);
};
export const ImportYearPlan20211213 = params => {
  return axios.post(`/wt/api/YearPlan/ImportYearPlan20211213`, params);
};
export const DecomposesYearPlan = params => {
  return axios.post(`/wt/api/YearPlan/DecomposesYearPlan`, params);
};
export const GetYearPlanListByUserCode = params => {
  return axios.get(`/wt/api/YearPlan/GetYearPlanListByUserCode`, { params: params });
}

export const QueryConfigInfoByYearPlanId = params => {
  return axios.get(`/wt/api/YearPlanConfig/QueryConfigInfoByYearPlanId`, { params: params });
}

export const SetTaskAboutYearPlan = params => {
  return axios.post(`/wt/api/Task/SetTaskAboutYearPlan?taskId=`+ params.taskId + `&yearplanId=` +params.yearplanId);
}

//跨系统、部门协同模块管理
export const QueryPageOrganizeCoByUserCode = params => {
  return axios.get(`/wt/api/OrganizeCollaboration/QueryPageOrganizeCoByUserCode`, { params: params });
};
export const AddOrganizeCo = params => {
  return axios.post(`/wt/api/OrganizeCollaboration/AddOrganizeCo`, params);
};
export const QueryOrganizeChildTasksByParentId = params => {
  return axios.get(`/wt/api/OrganizeCollaboration/QueryChildTasksByParentId`, { params: params });
}
//例会维护模块管理
export const QueryMeetingUphold = params => {
  return axios.get(`/wt/api/MeetingUphold/QueryMeetingUphold`, { params: params });
};
export const AddRegularMeeting = params => {
  return axios.post(`/wt/api/MeetingUphold/AddMeeting`, params);
};
export const QueryMeetingUpholdByCreate = params => {
  return axios.get(`/wt/api/MeetingUphold/QueryMeetingUpholdByCreate`, { params: params });
}
export const DeleteMeetingUpholdById = params => {
  return axios.delete(`/wt/api/MeetingUphold/DeleteMeetingUpholdById`, { params: params });
};
export const UpdateMeetingUphold = params => {
  return axios.put(`/wt/api/MeetingUphold/UpdateMeetingUphold`, params);
}
export const UpdateMeetingUpholdCCUser = params => {
  return axios.put(`/wt/api/MeetingUphold/UpdateMeetingUpholdCCUser`, params);
}
//查阅权限配置管理
export const QueryAllSearchQuanxianList = params => {
  return axios.get(`/wt/api/SearchQuanxian/QueryAllSearchQuanxianList`, { params: params });
}
export const QuerySearchQuanxianListByUsercode = params => {
  return axios.get(`/wt/api/SearchQuanxian/QuerySearchQuanxianListByUsercode?usercode=`+params.usercode, { params: params });
}
export const FormatDepartment = params => {
  return axios.post(`/wt/api/SearchQuanxian/FormatDepartment`, params.formatdpt );
}
export const AddSearchQuanxian = params => {
  return axios.post(`/wt/api/SearchQuanxian/AddSearchQuanxian`, params);
};
export const DeleteSearchQuanxianById = params => {
  return axios.delete(`/wt/api/SearchQuanxian/DeleteSearchQuanxianById`, { params: params });
};
export const UpdateSearchQuanxian = params => {
  return axios.put(`/wt/api/SearchQuanxian/UpdateSearchQuanxian`, params);
}
//例会项目管理模块
export const AddRoutineMeeting = params => {
  return axios.post(`/wt/api/RoutineMeetingProject/AddRoutineMeeting`, params);
};
export const QueryPageRoutineMeetingTasksByUserCode = params => {
  return axios.get(`/wt/api/RoutineMeetingProject/QueryPageRoutineMeetingTasksByUserCode`, { params: params });
}
export const QueryPageRoutineMeetingTasksByUserCode20210121 = params => {
  return axios.get(`/wt/api/RoutineMeetingProject/QueryPageRoutineMeetingTasksByUserCode20210121`, { params: params });
}
//专项会议项目管理模块
export const QueryPageUniquelyProjectMeetingTasksByUserCode = params => {
  return axios.get(`/wt/api/UniquelyProjectMeeting/QueryPageUniquelyProjectMeetingTasksByUserCode`, { params: params });
};
export const QueryPageUniquelyProjectMeetingTasksByUserCode20210122 = params => {
  return axios.get(`/wt/api/UniquelyProjectMeeting/QueryPageUniquelyProjectMeetingTasksByUserCode20210122`, { params: params });
};
export const AddUniquelyProjectMeeting = params => {
  return axios.post(`/wt/api/UniquelyProjectMeeting/AddUniquelyProjectMeeting`, params);
}
//日历模块管理
export const QueryScheduleByUser = params => {
  return axios.get(`/wt/api/Schedule/QueryScheduleByUser`, { params: params });
};
export const AddSchedule = params => {
  return axios.post(`/wt/api/Schedule/AddSchedule`, params);
}
//消息模块管理
export const QueryMessageListByUserCode = params => {
  return axios.get(`/wt/api/Message/QueryMessageListByUserCode`, { params: params });
};
export const QueryNewMessageCountByUserCode = params =>{
  return axios.get(`/wt/api/Message/QueryNewMessageCountByUserCode`, { params: params });
};
export const UpdateMessageStatusById = params => {
  return axios.put(`/wt/api/Message/UpdateMessageStatusById?messageId=`+ params.messageId + `&userCode=` +params.userCode);
}

//通知模块管理
export const GetNoticeList = params =>{
  return axios.get(`/wt/api/Notice/GetNoticePageByTitle`, { params: params });
}
export const Add = params => {
  return axios.post(`/wt/api/Notice/AddNotice`, params);
}
export const UpdateNotice = params => {
  return axios.put(`/wt/api/Notice/UpdateNotice`, params);
}
export const GetDetail = params => {
  return axios.get(`/wt/api/Notice/GetNoticeDetailById`, { params: params });
}
export const Delete = params => {
  return axios.delete(`/wt/api/Notice/DeleteNoticeById`, { params: params });
}


export const GetUserTopDepartmentList = params => {
  return axios.get(`/uc/api/Department/GetDepartmentNodeRootAllByUserId`, { params: params });
}
export const GetChildrenList = params => {
  return axios.get(`/uc/api/Department/GetDepartmentNodeAllByParentId`, { params: params });
};
export const GetDepartmentPostUserList = params => {
  return axios.get(`/uc/api/Department/GetDepartmentUserViewPageById`, { params: params });
}
//职务模块管理
export const GetPostTree = params => {
  return axios.get(`/uc/api/Post/GetTreeViewByRootId`, { params: params });
}
export const GetUserPostList = params => {
  return axios.get(`/uc/api/Post/GetPostUserViewAllByUserId`, { params: params });
}
export const GetPostUserPrincipalList = params => {
  return axios.get(`/uc/api/Post/GetPostUserViewPageByUserCodeOrName`, { params: params });
}
export const GetDepartmentList = params => {
  return axios.get(`/uc/api/Department/GetDepartmentNodeAllByName`, { params: params });
}
export const GetPostDepartmentOrganizationViewAllByUserId = params => {
  return axios.get(`/uc/api/Post/GetPostDepartmentOrganizationViewAllByUserId`, { params: params });
}
export const GetPostNodeUserViewPageByUserId = params => {
  return axios.get(`/uc/api/Post/GetPostNodeUserViewPageByUserId`, { params: params });
}
export function getOrganizeList (params) {
  return axios.get(`/uc/api/Department/GetOrganizationAll`);
};
export function GetOrganizationAllAndAmount (params) {
  return axios.get(`/uc/api/Department/GetOrganizationAllAndAmount?postWorkTypeName=后勤`);
};
export const AddTaskPingfen = params => {
  return axios.post(`/wt/api/TaskPingfen/AddTaskPingfen`, params);
};
export const UpdateTaskPingfen = params => {
  return axios.post(`/wt/api/TaskPingfen/UpdateTaskPingfen`, params);
};
export const DeleteTaskPingfen = params => {
  return axios.post(`/wt/api/TaskPingfen/DeleteTaskPingfen?id=`+params.id, params);
};
export const QueryTaskPingfenByTaskId = params => {
  return axios.get(`/wt/api/TaskPingfen/QueryTaskPingfenByTaskId`, { params: params });
};