import axios from "./api";

let clyHR = `/hr/`;
let clyOrg = `/cyl/`;
if (process.env.NODE_ENV == "production") { // 生产环境
     //clyHR = `https://api.gradgroup.cn/hr/`;
     //clyOrg = `https://api.gradgroup.cn/`;
    clyHR = `/api/hr/`;
    clyOrg = `/api/`;
}
else if (process.env.NODE_ENV == "development") {// 开发环境
    //  clyHR = `/hr/`;
    //  clyOrg = `/cyl/`;
    clyHR = `https://api.gradgroup.cn/hr/`;
    //clyHR = `http://192.16.81.129:8116/`;
    clyOrg = `https://api.gradgroup.cn/`;
}
// 用户管理
export function getUserListPage (params) {
  return axios.get(`/uc/api/User/GetUserNameClaimPageByName`, {
    params: params,
  });
}
export function removeUser (params) {
  return axios.delete(`/uc/api/User/DeleteUserById`, { params: params });
}
// 解锁用户
export function putUpdateUserUnLockById(params) {
    return axios.put(`/uc/api/User/UpdateUserUnLockById`, params);
}
// 锁定用户
export function putUpdateUserLock(params) {
    return axios.put(`/uc/api/User/UpdateUserLock`, params);
}

export function editUser (params) {
  return axios.put(`/uc/api/User/UpdateUserAndNameClaim`, params);
}
export function addUser (params) {
  return axios.post(`/uc/api/User/AddUserNameClaim`, params);
}

export function GetUserChooseList (params) {
  return axios.post(`/uc/api/User/GetUserAllByIds`, params);
}
export function SetPassword (params) {
  return axios.put(`/uc/api/User/UpdateUserPassword`, params);
}
export function GetProbationSubmitInfoPageList (params) {
  return axios.get(clyHR + `Probation/GetSubmitInfoPageList`, {
    params: params,
  });
}
export function GetProbationSaveState (params) {
  return axios.get(clyHR + `Probation/SaveState`, { params: params });
}
//删除试用
export function DeleteProbationInfo (params) {
  return axios.delete(clyHR + `Probation/DelInfo`, { params: params });
}
// 角色管理
export function getRoleListPage (params) {
  return axios.get(clyHR + `Role/GetRolePageByName`, { params: params });
}
export function removeRole (params) {
  return axios.delete(clyHR + `Role/DeleteRoleById`, { params: params });
}
export function editRole (params) {
  return axios.put(clyHR + `Role/UpdateRole`, params);
}
export function addRole (params) {
  return axios.post(clyHR + `Role/AddRole`, params);
}
export function GetRoleUserList (params) {
  return axios.get(clyHR + `Role/GetUserClaimViewPageByRoleIdAndName`, {
    params: params,
  });
}

//用户角色管理模块
export function AddUserRole (params) {
  return axios.post(clyHR + `Role/UpdateUserKeys`, params);
}
export function DeleteUserRole (params) {
  return axios.delete(clyHR + `Role/DeleteRoleUserByRoleIdAndUserId`, {
    params: params,
  });
}

// 接口模块管理
export function getModuleListPage (params) {
  return axios.get(clyHR + `Interface/GetInterfaceList`, { params: params });
}
export function getModuleList (params) {
  return axios.get(clyHR + `Interface/Get`, { params: params });
}
export function removeModule (params) {
  return axios.delete(clyHR + `Interface/delete`, { params: params });
}
export function editModule (params) {
  return axios.put(clyHR + `Interface/put`, params);
}
export function addModule (params) {
  return axios.post(clyHR + `Interface/post`, params);
}
//字典模块管理
export function getSupplyCategoryList (params) {
  return axios.get(clyHR + `Dictionary/get`, { params: params });
}
//部门模块管理
export function GetChildrenList (params) {
  //return axios.get(`/uc/api/Department/GetDepartmentNodeAllByParentId`, {
  //  params: params,
  //});
    return axios.get(clyOrg + `org/dept/orgDeptList`, {
        params: params,
    });
}
export function GetManagePost (params) {
  //return axios.get(`/uc/api/Post/GetPostNodeManageByDepartmentId`, {
  //  params: params,
  //});
    return axios.get(clyOrg + `org/job/getJobList`, {
        params: params,
    });
}
export function AddDept (params) {
  return axios.post(`/uc/api/Department/AddDepartment`, params);
}
export function Update (params) {
  return axios.put(`/uc/api/Department/UpdateDepartment`, params);
}
export function DeleteDept (params) {
  return axios.delete(`/uc/api/Department/DeleteDepartmentById`, {
    params: params,
  });
}
export function GetDepartment (params) {
  return axios.get(`/uc/api/Department/GetDepartmentNodeById`, {
    params: params,
  });
}
export function GetDepartmentPostUserList (params) {
  //return axios.get(`/uc/api/Department/GetDepartmentUserViewPageById`, {
  //  params: params,
  //});
    return axios.get(clyOrg + `org/user/getUserList`, {
        params: params,
    });
}
export function GetDepartmentList (params) {
  //return axios.get(`/uc/api/Department/GetDepartmentNodeAllByName`, {
  //  params: params,
  //});
    return axios.get(clyOrg + `org/dept/orgDeptList`, {
        params: params,
    });
}
export function GetDepartmentTree (params) {
  //return axios.get(`/uc/api/Department/GetTreeViewByRootId`, {
  //  params: params,
  //});
    return axios.get(clyOrg + `org/dept/orgDeptList`, {
        params: params,
    });
}
//部门角色
export function GetDepartmentRoleList (params) {
  return axios.get(clyHR + `Role/GetDepartmentKeysByRoleId`, {
    params: params,
  });
}
export function AssignDepartmentRole (params) {
  return axios.post(clyHR + `Role/UpdateDepartmentKeys`, params);
}
//部门类型模块管理
export function GetDepartmentTypes (params) {
  return axios.get(`/uc/api/Department/GetDepartmentTypePageByName`, {
    params: params,
  });
}
export function AddDptTypes (params) {
  return axios.post(`/uc/api/Department/AddDepartmentType`, params);
}
export function UpdateDptTypes (params) {
  return axios.put(`/uc/api/Department/UpdateDepartmentType`, params);
}
export function DeleteDptTypes (params) {
  return axios.delete(`/uc/api/Department/DeleteDepartmentTypeById`, {
    params: params,
  });
}
//部门级别管理模块
export function GetDepartmentRanks (params) {
  return axios.get(`/uc/api/Department/GetDepartmentRankPageByName`, {
    params: params,
  });
}
export function AddDptRanks (params) {
  return axios.post(`/uc/api/Department/AddDepartmentRank`, params);
}
export function UpdateDptRanks (params) {
  return axios.put(`/uc/api/Department/UpdateDepartmentRank`, params);
}
export function DeleteDptRanks (params) {
  return axios.delete(`/uc/api/Department/DeleteDepartmentRankById`, {
    params: params,
  });
}
//职务级别管理模块
export function GetPostTypes (params) {
  return axios.get(`/uc/api/Post/GetPostRankPageByName`, { params: params });
}
export function AddPostRank (params) {
  return axios.post(`/uc/api/Post/AddPostRank`, params);
}
export function UpdatePostRank (params) {
  return axios.put(`/uc/api/Post/UpdatePostRank`, params);
}
export function DeletePostRank (params) {
  return axios.delete(`/uc/api/Post/DeletePostRankById`, { params: params });
}
//职务工作类型管理模块
export function GetWorkTypes (params) {
  //return axios.get(`/uc/api/Post/GetPostWorkTypePageByName`, {
  //  params: params,
  //});
    return axios.get(clyOrg + `org/job/getJobWorkTypeList`, {
        params: params,
    });
}
//按钮模块管理
export function buttonEdit (params) {
  return axios.put(clyHR + `Button/put`, params);
}
export function buttonPost (params) {
  return axios.post(clyHR + `Button/post`, params);
}
export function buttonDelete (params) {
  return axios.delete(clyHR + `Button/delete`, { params: params });
}
export function GetButtonInterfaceIds (params) {
  return axios.get(clyHR + `Button/GetButtonInterfaceIds`, { params: params });
}
//职务模块管理
export function GetPostChildrenList (params) {
  return axios.get(`/uc/api/Post/GetPostNodeAllById`, { params: params });
}
export function GetPostList (params) {
  return axios.get(`/uc/api/Post/GetPostNodeAllByName`, { params: params });
}
export function GetPost (params) {
  return axios.get(`/uc/api/Post/GetPostNodeById`, { params: params });
}
export function AddPost (params) {
  return axios.post(`/uc/api/Post/AddPost`, params);
}
export function UpdatePost (params) {
  return axios.put(`/uc/api/Post/UpdatePost`, params);
}
export function DeletePost (params) {
  return axios.delete(`/uc/api/Post/DeletePostById`, { params: params });
}
export function GetPostTree (params) {
  return axios.get(`/uc//api/Post/GetTreeViewByRootId`, { params: params });
}
//职务角色
export function GetPostRoleList (params) {
  return axios.get(clyHR + `Role/GetPostKeysByRoleId`, { params: params });
}
export function AssignPostRole (params) {
  return axios.post(clyHR + `Role/UpdatePostKeys`, params);
}
//职务用户关联管理模块
export function GetPostUserList (params) {
  return axios.get(`/uc/api/Post/GetPostUserViewPageByPostId`, {
    params: params,
  });
}
export function GetUserPostList (params) {
  return axios.get(`/uc/api/Post/GetPostUserViewAllByUserId`, {
    params: params,
  });
}
export function SetPrincipal (params) {
  return axios.put(`/uc/api/Post/UpdatePostPrincipal`, params);
}
export function DeleteUser (params) {
  return axios.delete(`/uc/api/Post/DeletePostUserByPostIdAndUserId`, {
    params: params,
  });
}
export function AddNewUser (params) {
  return axios.post(`/uc/api/Post/AddPostUser`, params);
}
export function BacthPostUsers (params) {
  return axios.post(`/uc/api/Post/BacthAddDeletePostUser`, params);
}
export function GetPostUserPrincipalList (params) {
  return axios.get(`/uc/api/Post/GetPostUserViewPageByUserCodeOrName`, {
    params: params,
  });
}
export function GetPostUserViewPageByDto (params) {
    //return axios.post(`/uc/api/Post/GetPostUserViewPageByDto`, params);
    return axios.get(clyOrg + `org/user/getUserList`, { params: params});
}
// 菜单模块管理
export function getPermissionListPage (params) {
  return axios.get(clyHR + `Menu/get`, { params: params });
}
export function getPermissionTreeTable (params) {
  return axios.get(clyHR + `Menu/GetMenuTreeTable`, { params: params });
}
export function removePermission (params) {
  return axios.delete(clyHR + `Menu/delete`, { params: params });
}
export function editPermission (params) {
  return axios.put(clyHR + `Menu/put`, params);
}

export function addPermission (params) {
  return axios.post(clyHR + `Menu/post`, params);
}
export function addRolePermission (params) {
  return axios.post(clyHR + `Menu/Assign`, params);
}
export function getNavigationBar (params) {
  return axios
    .get(clyHR + `Menu/GetNavigationBar`, { params: params })
    .then((res) => res.data);
}
export function GetLinkById (params) {
  return axios.get(clyHR + `Menu/GetLinkById`, { params: params });
}
// 应聘登记
export function AddUserBaseInfoLogIn (params) {
  console.log(params);
  return axios.post(clyHR + `UserBaseInfo/AddUserBaseInfo`, params);
}
// 应聘登记无登陆
export function AddUserBaseInfo(params) {
    console.log(params);
    return axios.post(clyHR + `UserBaseInfo/AddUserBaseInfo`, params);
}
export function getUserBaseInfoList (params) {
  return axios.get(clyHR + `UserBaseInfo/GetUserBaseInfoList`, {
    params: params,
  });
}
export function getUserBaseInfo (params) {
  return axios.get(clyHR + `UserBaseInfo/GetUserBaseInfo`, { params: params });
}
export function UpdateUserBaseInfo (params) {
  return axios.put(clyHR + `UserBaseInfo/UpdateUserBaseInfo`, params);
}
export function AddInterview (params) {
  return axios.post(clyHR + `UserInterview/AddInterview`, params);
}
// 获取面试列表 
export function getInterviewList (params) {
  return axios.get(clyHR + `UserInterview/GetInterviewList`, {
    params: params,
  });
}
export function getPositiveDetail (params) {
  return axios.get(clyHR + `Probation/GetInfo`, { params: params });
}
export function getGetPositiveDetail (params) {
  return axios.get(clyHR + `Positive/GetInfo`, { params: params });
}
//删除转正
export function DeletePositiveInfo (params) {
  return axios.delete(clyHR + `Positive/DelInfo`, { params: params });
}

//获取所有公司列表
export function getOrganizeList (params) {
    //return axios.get(`/uc/api/Department/GetOrganizationAll`);

    return axios.get(clyOrg + `org/dept/getOrgDeptUserCount`, {
        params: params,
    });
}
//获取所有试用记录
export function getProbationPageList (params) {
  return axios.get(clyHR + `Probation/GetInfoPageList`, { params: params });
}
//获取所有试用记录
export function getProbationInfoList (params) {
  return axios.get(clyHR + `Probation/GetInfoList`, { params: params });
}

//获取工资类型
export function getInfoList (params) {
  return axios.get(clyHR + `UserSalaryType/GetInfoList`);
}
export function UpdateInfo (params) {
  return axios.put(clyHR + `UserSalaryType/UpdateInfo`, params);
}
export function AddInfo (params) {
  return axios.post(clyHR + `UserSalaryType/AddInfo`, params);
}
//获取职务待遇
export function GetUserDis (params) {
  return axios.get(clyHR + `UserDisPosition/GetInfoList`);
}
export function UpdateIUserDis (params) {
  return axios.put(clyHR + `UserDisPosition/UpdateInfo`, params);
}
export function AddUserDis (params) {
  return axios.post(clyHR + `UserDisPosition/AddInfo`, params);
}
export function AddProbation (params) {
  return axios.post(clyHR + `Probation/AddProbation`, params);
}
export function GetInterviewPageList (params) {
  return axios.get(clyHR + `UserInterview/GetInterviewPageList`, {
    params: params,
  });
}
//添加转正记录
export function AddPositive (params) {
  return axios.post(clyHR + `Positive/AddInfo`, params);
}
export function GetPositivePageList (params) {
  return axios.get(clyHR + `Positive/GetInfoPageList`, { params: params });
}
//更新试用记录
export function UpdateProbation (params) {
  return axios.put(clyHR + `Positive/UpdateProbation`, params);
}
//更新转正记录
export function UpdatePositive (params) {
  return axios.put(clyHR + `Positive/UpdateInfo`, params);
}
//更新面试记录
export function UpdateInterview (params) {
  return axios.put(clyHR + `UserInterview/UpdateInterview`, params);
}
//获取所有证书信息
export function GetCertificatePageList (params) {
  return axios.get(clyHR + `Certificate/GetInfoPageList`, { params: params });
}
export function AddCertificate (params) {
  return axios.post(clyHR + `Certificate/AddInfo`, params);
}

export function UpdateCertificate (params) {
  return axios.put(clyHR + `Certificate/UpdateInfo`, params);
}
//获取所有证书类型
export function GetCertificateTypePageList (params) {
  return axios.get(clyHR + `DicCertificate/GetInfoPageList`, {
    params: params,
  });
}
export function AddCertificateType (params) {
  return axios.post(clyHR + `DicCertificate/AddInfo`, params);
}
export function UpdateCertificateType (params) {
  return axios.put(clyHR + `DicCertificate/UpdateInfo`, params);
}
export function GetCertificateByType (params) {
  return axios.get(clyHR + `DicCertificate/GetInfoList`, { params: params });
}
//获取社保五险字典表列表
export function GetDicInsurancePageList (params) {
  return axios.get(clyHR + `DicInsurance/GetInfoPageList`, { params: params });
}
export function AddDicInsurance (params) {
  return axios.post(clyHR + `DicInsurance/AddInfo`, params);
}
export function UpdateDicInsurance (params) {
  return axios.put(clyHR + `DicInsurance/UpdateInfo`, params);
}
export function GetInsuranceMonthPageList (params) {
  return axios.get(clyHR + `InsuranceMonth/GetInfoPageList`, {
    params: params,
  });
}
export function AddInsuranceMonth (params) {
  return axios.post(clyHR + `InsuranceMonth/AddInfo`, params);
}
export function DeleteInsuranceMonth (params) {
  return axios.delete(clyHR + `InsuranceMonth/DelInfo`, { params: params });
}
export function GetInsuranceReportPageList (params) {
  return axios.get(clyHR + `InsuranceReport/GetInfoPageList`, {
    params: params,
  });
}
export function UpdateInsuranceReport (params) {
  return axios.put(clyHR + `InsuranceReport/UpdateInfo`, params);
}
export function GetInsurancePersonPageList (params) {
  return axios.get(clyHR + `InsurancePerson/GetInfoPageList`, {
    params: params,
  });
}
export function GetInsurancePersonUserID (params) {
  return axios.get(clyHR + `InsurancePerson/GetInfoByUserId`, {
    params: params,
  });
}
export function AddInsurancePerson (params) {
  return axios.post(clyHR + `InsurancePerson/AddInfo`, params);
}
export function AddReduceInfo (params) {
  return axios.post(clyHR + `InsurancePerson/ReduceInfo`, params);
}
export function UpdateInsurancePerson (params) {
  return axios.put(clyHR + `InsurancePerson/UpdateInfo`, params);
}
export function UpdateInsuranceReportSalary (params) {
  return axios.put(clyHR + `InsuranceReport/BatchUpdateInfoSalary`, params);
}

//调动列表
export function GetTransferPageList (params) {
  return axios.get(clyHR + `Transfer/GetInfoPageList`, { params: params });
}
export function GetDepartmentOrg (params) {
  //return axios.get(`/uc/api/Department/GetDepartmentOrganizationById`, {
  //  params: params,
  //});
    return axios.get(clyOrg + `org/dept/getCompanyInfo`, {
        params: params,
    });
}
export function AddTransferInfo (params) {
  return axios.post(clyHR + `Transfer/AddInfo`, params);
}
export function UpdateTransfer (params) {
  return axios.put(clyHR + `Transfer/UpdateInfo`, params);
}
export function GetDicContractTypePageList (params) {
  return axios.get(clyHR + `DicContract/GetInfoPageList`, { params: params });
}
export function AddDicContractType (params) {
  return axios.post(clyHR + `DicContract/AddInfo`, params);
}
export function UpdateDicContractType (params) {
  return axios.put(clyHR + `DicContract/UpdateInfo`, params);
}
export function GetContractPageList (params) {
  return axios.get(clyHR + `Contract/GetInfoPageList`, { params: params });
}
export function GetContractDetailPageList(params) {
    return axios.get(clyHR + `Contract/GetDetailInfoPageList`, { params: params });
}
export function AddContract (params) {
  return axios.post(clyHR + `Contract/AddInfo`, params);
}
export function UpdateContract (params) {
  return axios.put(clyHR + `Contract/UpdateInfo`, params);
}
// 合同时间验证
export function GetContractVerify(params) {
    return axios.get(clyHR + `Contract/verify`, { params: params });
}

// 添加合同
export function AddContractDetail(params) {
    return axios.post(clyHR + `Contract/AddContractDetail`, params);
}
// 修改合同
export function ModifContractDetail(params) {
    return axios.post(clyHR + `Contract/ModifContractDetail`, params);
}
// 解除合同
export function TerminateContractDetail(params) {
    return axios.post(clyHR + `Contract/TerminateContractDetail`, params);
}

export function UpdateSetInfoSate (params) {
  console.log(params);
  return axios.put(clyHR + `InsuranceMonth/SetInfoSate`, params);
}
export function GetPersonAddExcelList (params) {
  return axios.get(clyHR + `InsuranceMonth/GetPersonAddExcelList`, {
    params: params,
  });
}
export function GetPersonReduceExcelList (params) {
  return axios.get(clyHR + `InsuranceMonth/GetPersonReduceExcelList`, {
    params: params,
  });
}
export function GetPersonHistoryList (params) {
  return axios.get(clyHR + `InsuranceMonth/GetPersonHistoryList`, {
    params: params,
  });
}
export function GetTransferInfoDetail (params) {
  return axios.get(clyHR + `Transfer/GetInfo`, { params: params });
}

//职称管理
export function GetProfessionnfoList (params) {
  return axios.get(clyHR + `Profession/GetInfoPageList`, { params: params });
}
export function AddProfessionInfo (params) {
  return axios.post(clyHR + `Profession/AddInfo`, params);
}
export function UpdateProfession (params) {
  return axios.put(clyHR + `Profession/UpdateInfo`, params);
}
export function GetProfessionTopList (params) {
  return axios.get(clyHR + `ProfessionTop/GetInfoPageList`, { params: params });
}
export function AddProfessionTopInfo (params) {
  return axios.post(clyHR + `ProfessionTop/AddInfo`, params);
}
export function UpdateProfessionTop (params) {
  return axios.put(clyHR + `ProfessionTop/UpdateInfo`, params);
}
export function GetRewardsList (params) {
  return axios.get(clyHR + `Rewards/GetInfoPageList`, { params: params });
}
export function AddRewards (params) {
  return axios.post(clyHR + `Rewards/AddInfo`, params);
}
export function AddListRewards(params) {
    return axios.post(clyHR + `Rewards/AddInfoList`, params);
}

export function UpdateRewards (params) {
  return axios.put(clyHR + `Rewards/UpdateInfo`, params);
}
export function GetHonorList (params) {
  return axios.get(clyHR + `Honor/GetInfoPageList`, { params: params });
}
export function AddHonor (params) {
  return axios.post(clyHR + `Honor/AddInfo`, params);
}
export function UpdateHonor (params) {
  return axios.put(clyHR + `Honor/UpdateInfo`, params);
}
export function GetCertificateDetail (params) {
  return axios.get(clyHR + `Certificate/GetInfo`, { params: params });
}
export function GetContractDetail (params) {
  return axios.get(clyHR + `Contract/GetInfo`, { params: params });
}
export function GetRewardsDetail (params) {
  return axios.get(clyHR + `Rewards/GetInfo`, { params: params });
}
export function GetHonorDetail (params) {
  return axios.get(clyHR + `Honor/GetInfo`, { params: params });
}
//根据用户id获取部门职务公司
export function GetUserPostDptOrg (params) {
  //return axios.get(
  //  `/uc/api/Post/GetPostDepartmentOrganizationViewAllByUserId`,
  //  { params: params }
  //  );
    //return axios.get(
    //    clyOrg + `org/user/getUserDetailList`,
    //    { params: params }
    //);
    return axios.get(clyHR + `Org/GetInfo`,{ params: params });
}
export function GetUserPostDptOrgList(params) {
    return axios.get(clyHR + `Org/GetPostInfo`,{ params: params });
}
//根据用户id获取部门职务公司
export function GetUserDetailsByUserIds(params) {
    //return axios.get(
    //  `/uc/api/Post/GetPostDepartmentOrganizationViewAllByUserId`,
    //  { params: params }
    //  );
    return axios.post(clyOrg + `org/user/findUserDetailsByUserIds`, params);
}
//离职管理
export function GetDimissionList (params) {
  return axios.get(clyHR + `Dimission/GetInfoPageList`, { params: params });
}
export function AddDimission (params) {
  return axios.post(clyHR + `Dimission/AddInfo`, params);
}
export function UpdateDimission (params) {
  return axios.put(clyHR + `Dimission/UpdateInfo`, params);
}
export function GetDimissionDetail (params) {
  return axios.get(clyHR + `Dimission/GetInfo`, { params: params });
}
//删除离职记录
export function DeleteDimissionInfo (params) {
  return axios.delete(clyHR + `Dimission/DelDimInfo`, { params: params });
}

//获取社保五险字典表列表
export function GetDicHouseFundsPageList(params) {
    return axios.get(clyHR + `DicHouseFunds/GetInfoPageList`, { params: params });
}
export function AddDicHouseFunds(params) {
    return axios.post(clyHR + `DicHouseFunds/AddInfo`, params);
}
export function UpdateDicHouseFunds(params) {
    return axios.put(clyHR + `DicHouseFunds/UpdateInfo`, params);
}
//公积金管理
export function GetHouseFundsMonthList (params) {
  return axios.get(clyHR + `HouseFundsMonth/GetInfoPageList`, {
    params: params,
  });
}
export function AddHouseFundsMonth (params) {
  return axios.post(clyHR + `HouseFundsMonth/AddInfo`, params);
}
export function UpdateHouseFundsMonth (params) {
  return axios.put(clyHR + `HouseFundsMonth/SetInfoSate`, params);
}
export function DeleteHouseFundsMonth (params) {
  return axios.delete(clyHR + `HouseFundsMonth/DelInfo`, { params: params });
}
export function GetHouseFundsReportList (params) {
  return axios.get(clyHR + `HouseFundsReport/GetInfoPageList`, {
    params: params,
  });
}
export function UpdateHouseFundsReport (params) {
  return axios.put(clyHR + `HouseFundsReport/UpdateInfo`, params);
}
export function UpdateBatchUpdateInfoSalary (params) {
  return axios.put(clyHR + `HouseFundsReport/BatchUpdateInfoSalary`, params);
}
export function GetHouseFundsPersonAddExcelList (params) {
  return axios.get(clyHR + `HouseFundsMonth/GetPersonAddExcelList`, {
    params: params,
  });
}
export function GetHouseFundsPersonReduceExcelList (params) {
  return axios.get(clyHR + `HouseFundsMonth/GetPersonReduceExcelList`, {
    params: params,
  });
}
export function GetHouseFundsPersonHistoryList (params) {
  return axios.get(clyHR + `HouseFundsMonth/GetPersonHistoryList`, {
    params: params,
  });
}
export function GetHouseFundsPersonList (params) {
  return axios.get(clyHR + `HouseFundsPerson/GetInfoPageList`, {
    params: params,
  });
}
export function UpdateHouseFundsPerson (params) {
  return axios.put(clyHR + `HouseFundsPerson/UpdateInfo`, params);
}
export function AddHouseFundsPerson (params) {
  return axios.post(clyHR + `HouseFundsPerson/AddInfo`, params);
}
export function AddHouseFundsPersonReduceInfo (params) {
  return axios.post(clyHR + `HouseFundsPerson/ReduceInfo`, params);
}
//党员管理
export function GetPartyList (params) {
  return axios.get(clyHR + `Party/GetInfoPageList`, { params: params });
}
export function GetUserInfoPartyList (params) {
  return axios.get(clyHR + `UserInfo/GetInfoPageList`, { params: params });
}
export function AddParty (params) {
  return axios.post(clyHR + `Party/AddInfo`, params);
}
export function UpdateParty (params) {
  return axios.put(clyHR + `Party/UpdateInfo`, params);
}
export function GetPartyDetail (params) {
  return axios.get(clyHR + `Party/GetInfo`, { params: params });
}
export function DelPartyDetail(params) {
    return axios.delete(clyHR + `Party/DelInfo`, { params: params });
}
//个税管理
export function GetSalaryTaxMonthList (params) {
  return axios.get(clyHR + `SalaryTaxMonth/GetInfoPageList`, {
    params: params,
  });
}
export function AddSalaryTaxMonth (params) {
  return axios.post(clyHR + `SalaryTaxMonth/AddInfo`, params);
}
export function DeleteSalaryTaxMonth (params) {
  return axios.delete(clyHR + `SalaryTaxMonth/DelInfo`, { params: params });
}
export function UpdateSalaryTaxMonth (params) {
  return axios.put(clyHR + `SalaryTaxMonth/SetInfoSate`, params);
}
export function GetSalaryList (params) {
  return axios.get(clyHR + `SalaryTax/GetInfoPageList`, { params: params });
}
export function AddSalaryTax (params) {
  return axios.post(clyHR + `SalaryTax/AddInfo`, params);
}
export function DeleteSalaryTax (params) {
  return axios.delete(clyHR + `SalaryTax/DelInfo`, { params: params });
}
export function UpdateSalaryTax (params) {
  return axios.put(clyHR + `SalaryTax/UpdateInfo`, params);
}
//薪资考勤管理
export function GetSalaryExcelMonthList (params) {
  return axios.get(clyHR + `SalaryExcelMonth/GetInfoPageList`, {
    params: params,
  });
}
export function AddSalaryExcelMonth (params) {
  return axios.post(clyHR + `SalaryExcelMonth/AddInfo`, params);
}
export function DeleteSalaryExcelMonth (params) {
  return axios.delete(clyHR + `SalaryExcelMonth/DelInfo`, { params: params });
}
export function UpdateSalaryExcelMonth (params) {
  return axios.put(clyHR + `SalaryExcelMonth/SetInfoSate`, params);
}
export function GetSalaryExcelList (params) {
  return axios.get(clyHR + `SalaryExcel/GetInfoPageList`, { params: params });
}
export function AddSalaryExcel (params) {
  return axios.post(clyHR + `SalaryExcel/AddInfo`, params);
}
export function DeleteSalaryExcel (params) {
  return axios.delete(clyHR + `SalaryExcel/DelInfo`, { params: params });
}
export function UpdateSalaryExcel (params) {
  return axios.put(clyHR + `SalaryExcel/UpdateInfo`, params);
}
//薪资月度
export function GetSalaryMonthList (params) {
  return axios.get(clyHR + `SalaryMonth/GetInfoPageList`, { params: params });
}
export function AddSalaryMonth (params) {
  return axios.post(clyHR + `SalaryMonth/AddInfo`, params);
}
export function DeleteSalaryMonth (params) {
  return axios.delete(clyHR + `SalaryMonth/DelInfo`, { params: params });
}
export function UpdateSalaryMonth (params) {
  return axios.put(clyHR + `SalaryMonth/SetInfoSate`, params);
}
export function GetSalaryReportList (params) {
  return axios.get(clyHR + `SalaryReport/GetInfoPageList`, { params: params });
}
export function UpdateSalaryReport (params) {
  return axios.put(clyHR + `SalaryReport/UpdateInfo`, params);
}
//薪资基础信息
export function GetSalaryBanksList (params) {
  return axios.get(clyHR + `SalaryBanks/GetInfoPageList`, { params: params });
}
export function AddSalaryBanks (params) {
  return axios.post(clyHR + `SalaryBanks/AddInfo`, params);
}
export function UpdateSalaryBanks (params) {
  return axios.put(clyHR + `SalaryBanks/UpdateInfo`, params);
}
export function UpdateSalaryBanksState (params) {
  return axios.put(clyHR + `SalaryBanks/UpdateInfoState`, params);
}
export function GetSubmitInfoPageList (params) {
  return axios.get(clyHR + `SalaryBanks/GetSubmitInfoPageList`, {
    params: params,
  });
}
export function GetHonorInfoList (params) {
  return axios.get(clyHR + `Honor/GetInfoList`, { params: params });
}
export function GetHonorSubmitInfoPageList (params) {
  return axios.get(clyHR + `Honor/GetSubmitInfoPageList`, { params: params });
}
export function UpdateHonorInfoState (params) {
  return axios.put(clyHR + `Honor/UpdateInfoState`, params);
}
export function GetGroupFactoryList (params) {
  return axios.get(clyHR + `SalaryReport/GetGroupFactoryList`, {
    params: params,
  });
}
export function SalaryReportSubmitInfo (params) {
  return axios.get(clyHR + `SalaryReport/SubmitInfo`, { params: params });
}
export function SalaryReportDeductTax (params) {
  return axios.get(clyHR + `SalaryReport/DeductTax`, { params: params });
}
//获取当前登录用户的信息
export function GetCurrentUserInfo (params) {
  return axios.get(clyHR + `UserInfo/GetCurrentUserInfo`, { params: params });
}
export function GetAtPresentBaseInfo (params) {
  return axios.get(clyHR + `UserInfo/GetAtPresentBaseInfo`, { params: params });
}
export function ContractGetInfoList (params) {
  return axios.get(clyHR + `Contract/GetInfoList`, { params: params });
}
export function SalaryReportGetGroupInfoPageList (params) {
  return axios.get(clyHR + `SalaryReport/GetGroupInfoPageList`, {
    params: params,
  });
}
export function CertificateGetInfoList (params) {
  return axios.get(clyHR + `Certificate/GetInfoList`, { params: params });
}
export function TransferGetInfoList (params) {
  return axios.get(clyHR + `Transfer/GetInfoList`, { params: params });
}
export function HonorGetInfoList (params) {
  return axios.get(clyHR + `Honor/GetInfoList`, { params: params });
}
export function DimissionGetInfoList (params) {
  return axios.get(clyHR + `Dimission/GetInfoList`, { params: params });
}
export function PositiveGetPositiveList (params) {
  return axios.get(clyHR + `Positive/GetInfoList`, { params: params });
}
export function SalaryBanksGetInfoList (params) {
  return axios.get(clyHR + `SalaryBanks/GetInfoList`, { params: params });
}
export function ProfessionGetInfoList (params) {
  return axios.get(clyHR + `Profession/GetInfoList`, { params: params });
}
export function ProfessionTopGetInfoList (params) {
  return axios.get(clyHR + `ProfessionTop/GetInfoList`, { params: params });
}
export function GetAtPresentSalaryBanksInfo (params) {
  return axios.get(clyHR + `UserInfo/GetAtPresentSalaryBanksInfo`, {
    params: params,
  });
}
//承诺书
export function CommitmentLetterGetInfoInfo (params) {
  return axios.get(clyHR + `CommitmentLetter/GetInfoPageListByUser`, {
    params: params,
  });
}
export function CommitmentLetterAddInfo (params) {
  return axios.post(clyHR + `CommitmentLetter/AddInfo`, params);
}
export function CommitmentLetterUpdateInfo (params) {
  return axios.put(clyHR + `CommitmentLetter/UpdateInfo`, params);
}
export function CommitmentLetterRepulse (params) {
  return axios.get(clyHR + `CommitmentLetter/Repulse`, { params: params });
}
export function CommitmentLetterDelInfo (params) {
  return axios.delete(clyHR + `CommitmentLetter/DelInfo`, { params: params });
}
export function CommitmentLetterApproval (params) {
  return axios.get(clyHR + `CommitmentLetter/Approval`, { params: params });
}
export function CommitmentLetterGetInfoByWhere (params) {
  return axios.get(clyHR + `CommitmentLetter/GetInfoPageListByWhere`, {
    params: params,
  });
}
export function UserInfoGetInfoPageList (params) {
  return axios.get(clyHR + `UserInfo/GetInfoPageList`, { params: params });
}
export function UserInfoGetAllDissPageList(params) {
    return axios.get(clyHR + `UserInfo/GetAllDissPageList`, { params: params });
}
// 待招聘列表停止在招
export function SalaRecruitingApplyEnd (params) {
  return axios.get(clyHR + `SalaRecruitingApply/ApprovalComplete`, { params: params });
}
// 共享中心-团队管理人员列表
export function UserInfoGetInfoSharingCenter (params) {
  return axios.get(clyHR + `SalaTeamUser/GetInfoPageList`, { params: params });
}
// 共享中心-招聘需求信息列表
export function UserInfoGetInfoSalaRecruiting (params) {
  return axios.get(clyHR + `SalaRecruitingApply/GetPageList`, { params: params });
}
// 共享中心-招聘需求新增
export function AddSalaRecruiting (params) {
  return axios.post(clyHR + `SalaRecruitingApply/AddInfo`, params);
}

// 共享中心-关联应聘
export function AddLinkRecruiting (params) {
  return axios.post(clyHR + `SalaRecruitingApply/AddLink`, params);
}

// 共享中心-删除应聘关联
export function DelLinkRecruiting (params) {
  return axios.delete(clyHR + `SalaRecruitingApply/DelLink`, { params: params });
}

// 共享中心-应聘者推送
export function PushApplyUserRecruiting (params) {
  return axios.put(clyHR + `SalaRecruitingApply/PushApplyUser`, params );
}

// 共享中心-待招聘信息
export function GetExpectation (params) {
  return axios.get(clyHR + `SalaRecruitingApply/GetExpectationPageList`, {
    params: params,
  });
}
export function GetExpectationInfo (params) {
  return axios.get(clyHR + `SalaRecruitingApply/GetInfo`,{
    params: params,
});
}
// 共享中心-面试及录用列表
export function GetSalaInterlist (params) {
  return axios.get(clyHR + `SalaInterview/GetPageList`, {
    params: params,
  });
}
// 共享中心-获取面试及录用数据
export function GetSalaInterInfo (params) {
  return axios.get(clyHR + `SalaInterview/GetInfo`, {
    params: params,
  });
}
// 共享中心-面试及录用—添加面试官
export function AddInterViewExaminer (params) {
  return axios.post(clyHR + `SalaInterview/AddInterViewExaminer`, params);
}
// 共享中心-面试及录用—删除面试官
export function DelInterViewExaminer (params) {
  return axios.delete(clyHR + `SalaInterview/DelInterViewExaminer`, { params: params });
}
// 共享中心-面试及录用—添加面试通知
export function AddInterviewExtent (params) {
  return axios.post(clyHR + `SalaInterview/AddInterviewExtent`,params);
}
// 共享中心-面试及录用—更新面试通知
export function UpdataInterviewExtent(params) {
    return axios.put(clyHR + `SalaInterview/UpdataInterviewExtent`, params);
}
// 共享中心-面试及录用—添加面试官评价
export function AddInterviewAssessment (params) {
  return axios.post(clyHR + `SalaInterview/AddInterviewAssessment`,params);
}
// 共享中心-面试及录用—更新面试官评价
export function UpDataInterviewAssessment (params) {
  return axios.put(clyHR + `SalaInterview/UpDataInterviewAssessment`,params);
}
// 共享中心-面试及录用—获取面试官评价
export function GetInterviewAssessmentInfo (params) {
  return axios.get(clyHR + `SalaInterview/GetInterviewAssessmentInfo`,{
    params: params,
  });
}
// 共享中心-面试及录用—获取面试官评价权限
export function GetInterviewAssessmentPermission (params) {
  return axios.get(clyHR + `SalaInterview/GetInterviewAssessmentPermission`,{
    params: params,
  });
}
// 共享中心-面试及录用—添加用人部门评价
export function AddInterviewDpt (params) {
  return axios.post(clyHR + `SalaInterview/AddInterviewDpt`,params);
}
// 共享中心-面试及录用—获取用人部门评价
export function GetInterviewDptInfo (params) {
  return axios.get(clyHR + `SalaInterview/GetInterviewDptInfo`,{
    params: params,
  });
}
// 共享中心-面试及录用—更新用人部门评价
export function UpDataInterviewDpt (params) {
  return axios.put(clyHR + `SalaInterview/UpDataInterviewDpt`,params);
}
// 共享中心-面试及录用—添加或获取面试ID
export function GetOrAddExtentInfo (params) {
  return axios.post(clyHR + `SalaInterview/GetOrAddExtentInfo`,params);
}
// 共享中心-面试及录用—发起录用审批流程
export function AddProcess (params) {
  return axios.post(clyHR + `SalaInterview/AddProcess`,params);
}

//共享中心-岗位及编制列表
export function GetPostEstablishment (params) {
  return axios.get(clyHR + `SalaPostEstablishment/GetInfoPageList`,{
    params: params,
  });
}

//共享中心-岗位及编制流程
export function AddPostEstablishment (params) {
  return axios.post(clyHR + `SalaPostEstablishment/AddProcess`,params);
}

//共享中心-登记表-待招聘人员
export function RegisterGetInfo (params) {
  return axios.get(clyHR + `Register/GetInfoPageList`,{
    params: params,
  });
}

//共享中心-关联应聘登记
export function AddLinkApply (params) {
  return axios.post(clyHR + `SalaRecruitingApply/AddLinkApply`,params);
}

//共享中心-获取登记表记录
export function RegisterInfo (params) {
  return axios.get(clyHR + `Register/GetInfo`,{
    params: params,
  });
}

//共享中心-登记表-添加记录
export function RegisterAdd (params) {
  return axios.post(clyHR + `Register/AddInfo`,params);
}

//共享中心-登记表-更新记录
export function RegisterUpdateInfo (params) {
  return axios.put(clyHR + `Register/UpdateInfo`,params);
}

//共享中心-获取试用及转正列表
export function GetSubordinateInfoPageList (params) {
  return axios.get(clyHR + `Probation/GetSubordinateInfoPageList`,{
    params: params,
  });
}

//共享中心-获取调岗管理列表
export function GetManagePageInfo (params) {
  return axios.get(clyHR + `Transfer/GetManagePageInfo`,{
    params: params,
  });
}

//共享中心-奖惩列表
export function GetRewardsPageList (params) {
  return axios.get(clyHR + `Rewards/GetManagePageInfo`,{
    params: params,
  });
}

//共享中心-调岗管理模块触发调动流程
export function AddManageProcess (params) {
  return axios.post(clyHR + `Transfer/AddProcess`,params);
}


//共享中心-试用及转正  添加转正评价
export function AddProbationEvaluate (params) {
  return axios.post(clyHR + `ProbationEvaluate/AddProbationEvaluate`,params);
}

//共享中心-试用及转正  根据ID获取转正评价
export function GetProbationEvaluate (params) {
  return axios.get(clyHR + `ProbationEvaluate/GetInfo`,{
    params: params,
  });
}

//共享中心-试用及转正  根据试用ID获取转正评价
export function GetInfoBypPobationId (params) {
  return axios.get(clyHR + `ProbationEvaluate/GetInfoBypPobationId`,{
    params: params,
  });
}


//共享中心-试用及转正  更新转正评价
export function UpdateProbationEvaluate (params) {
  return axios.put(clyHR + `ProbationEvaluate/UpdateInfo`,params);
}

//共享中心-试用及转正  触发转正流程
export function ProbationEvaluate (params) {
  return axios.post(clyHR + `ProbationEvaluate/AddProcess`,params);
}

//入职人员待办理
export function GetFormalityPageList (params) {
  return axios.get(clyHR + `Probation/GetFormalityPageList`,{
    params: params,
  });
}

//入职人员待办理-入职手续已办理确认
export function ApprovalComplete (params) {
  return axios.get(clyHR + `Probation/ApprovalComplete`,{
    params: params,
  });
}

//转正人员待办理
export function GetPositiveFormalityPageList (params) {
  return axios.get(clyHR + `Positive/GetFormalityPageList`,{
    params: params,
  });
}

//转正人员待办理-入职手续已办理确认
export function PositiveApprovalComplete (params) {
  return axios.get(clyHR + `Positive/ApprovalComplete`,{
    params: params,
  });
}

//社保人员待办理
export function InsuranceFormality (params) {
  return axios.get(clyHR + `InsuranceFormality/GetInfoPageList`,{
    params: params,
  });
}

//社保人员待办理-社保办理确认
export function PostInsuranceFormality (params) {
  return axios.get(clyHR + `InsuranceFormality/ApprovalComplete`,{
    params: params,
  });
}

//社保人员待办理-获取指定人记录
export function GetInsuranceFormality (params) {
  return axios.get(clyHR + `/InsuranceFormality/GetInfoList`,{
    params: params,
  });
}


//分配权限
export function UserInfoCompetenceAddInfo (params) {
  return axios.post(clyHR + `UserInfoCompetence/AddInfoList`, params);
}
export function UserInfoCompetenceGetInfo (params) {
  return axios.get(clyHR + `UserInfoCompetence/GetInfoList`, {
    params: params,
  });
}
export function SalaryCompetenceAddInfo (params) {
  return axios.post(clyHR + `SalaryCompetence/AddInfoList`, params);
}
export function SalaryCompetenceGetInfo (params) {
  return axios.get(clyHR + `SalaryCompetence/GetInfoList`, { params: params });
}
export function DimissionCompetenceAddInfo (params) {
  return axios.post(clyHR + `DimissionCompetence/AddInfoList`, params);
}
export function DimissionCompetenceGetInfo (params) {
  return axios.get(clyHR + `DimissionCompetence/GetInfoList`, {
    params: params,
  });
}
//证书补贴
export function CertificateMonthGetInfo (params) {
  return axios.get(clyHR + `CertificateMonth/GetInfoPageList`, {
    params: params,
  });
}
export function CertificateMonthAddInfo (params) {
  return axios.post(clyHR + `CertificateMonth/AddInfo`, params);
}
export function CertificateMonthDelInfo (params) {
  return axios.delete(clyHR + `CertificateMonth/DelInfo`, { params: params });
}
export function CertificateMonthSetInfo (params) {
  return axios.put(clyHR + `CertificateMonth/SetInfoSate`, params);
}
export function CertificateMonthGetPerson (params) {
  return axios.get(clyHR + `CertificateMonth/GetPersonHistoryList`, {
    params: params,
  });
}
export function CertificateReport (params) {
  return axios.get(clyHR + `CertificateReport/GetInfoPageList`, {
    params: params,
  });
}
export function CertificateReportUpdate (params) {
  return axios.put(clyHR + `CertificateReport/UpdateInfo`, params);
}
export function GetAtPresentContractInfo (params) {
  return axios.get(clyHR + `UserInfo/GetAtPresentContractInfo`, {
    params: params,
  });
}
export function GetAttendGroupById (params) {
  return axios.get(clyHR + `UserInfo/GetAttendGroupById`, {
    params: params,
  });
}
export function ProbationUpdateInfo (params) {
  return axios.put(clyHR + `Probation/UpdateInfo`, params);
}
// 更新试用时间
export function ProbationUpdateDateInfo(params) {
    return axios.put(clyHR + `Probation/UpdateDateInfo`, params);
}
export function SalaryMonthGetPersonHistoryList (params) {
  return axios.get(clyHR + `SalaryMonth/GetPersonHistoryList`, {
    params: params,
  });
}
export function UserInfoGetFileArrayList (params) {
  return axios.get(clyHR + `UserInfo/GetFileArrayList`, { params: params });
}
export function UserInfosUpdateFileList (params) {
  return axios.put(clyHR + `UserInfo/UpdateFileList`, params);
}

export function UserUpdateUserImage (params) {
  return axios.put(clyHR + `UserInfo/UpdateUserImage`, params);
}
export function UserInfoGetRewardsList (params) {
  return axios.get(clyHR + `Rewards/GetInfoList`, { params: params });
}
export function SalaryBanksGetOldInfoListList (params) {
  return axios.get(clyHR + `SalaryBanks/GetOldInfoList`, { params: params });
}
//转正提醒
export function GetProbationRemindPageList (params) {
  return axios.get(clyHR + `Probation/GetInfoRemindPageList`, {
    params: params,
  });
}
//证书提醒
export function GetCertificateRemindPageList (params) {
  return axios.get(clyHR + `Certificate/GetInfoRemindPageList`, {
    params: params,
  });
}
//证书有效期提醒
export function GetCertificateValidityPageList(params) {
    return axios.get(clyHR + `Certificate/GetInfoValidityPageList`, {
        params: params,
    });
}
//证书补贴提醒
export function GetSalaryRemindPageList (params) {
  return axios.get(clyHR + `Certificate/GetSalaryRemindPageList`, {
    params: params,
  });
}
//合同到期提醒
export function GetContractRemindPageList (params) {
  return axios.get(clyHR + `Contract/GetInfoRemindPageList`, {
    params: params,
  });
}

//删除合同记录
export function DeleteContractInfo(params) {
    return axios.delete(clyHR + `Contract/DelContractInfo`, { params: params });
}

export function GetAgeRemindPageList (params) {
  return axios.get(clyHR + `UserInfo/GetAgeRemindPageList`, { params: params });
}
//获取模块子节点
export function ModuleGetNodeChildren (params) {
  return axios.get(clyHR + `Module/GetModuleNodeByApplicationIdAndParentId`, {
    params: params,
  });
}
//添加模块
export function ModuleAdd (params) {
  return axios.post(clyHR + `Module/AddModule`, params);
}
//编辑模块
export function ModuleUpdate (params) {
  return axios.put(clyHR + `Module/UpdateModule`, params);
}
//删除模块
export function ModuleDelete (params) {
  return axios.delete(clyHR + `Module/DeleteModulebyId`, { params: params });
}
//获取菜单子节点
export function MenuGetNodeChildren (params) {
  return axios.get(clyHR + `Menu/GetMenuNodeAllByModuleIdAndParentId`, {
    params: params,
  });
}
//添加菜单
export function MenuAdd (params) {
  return axios.post(clyHR + `Menu/AddMenu`, params);
}
//编辑菜单
export function MenuUpdate (params) {
  return axios.put(clyHR + `Menu/UpdateMenu`, params);
}
//删除菜单
export function MenuDelete (params) {
  return axios.delete(clyHR + `Menu/DeleteMenuById`, { params: params });
}
//获取菜单下按钮集合
export function ButtonGetListByMenuId (params) {
  return axios.get(clyHR + `Button/GetButtonAllByMenuId`, { params: params });
}
//添加按钮
export function ButtonAdd (params) {
  return axios.post(clyHR + `Button/AddButton`, params);
}
//编辑按钮
export function ButtonUpdate (params) {
  return axios.put(clyHR + `Button/UpdateButton`, params);
}
//删除按钮
export function ButtonDelete (params) {
  return axios.delete(clyHR + `Button/DeleteButtonById`, { params: params });
}

//获取应用模块菜单按钮树
export function getPermissionTree (params) {
  return axios.get(clyHR + `Role/GetNavigationTreeByApplicationId`, {
    params: params,
  });
}
//获取角色已分配权限标识
export function getAssignKeys (params) {
  return axios.get(clyHR + `Role/GetNavigationKeysByApplicationIdAndRoleId`, {
    params: params,
  });
}
//设置角色分配权限
export function setAssignKeys (params) {
  return axios.post(clyHR + `Role/UpdateNavigationKeys`, params);
}
//人员任免
export function OfficialPersonList (params) {
  return axios.get(clyHR + `OfficialPerson/GetInfoPageList`, {
    params: params,
  });
}
export function OfficialPersonInfo (params) {
  return axios.get(clyHR + `OfficialPerson/GetInfo`, { params: params });
}
export function OfficialPersonAdd (params) {
  return axios.post(clyHR + `OfficialPerson/AddInfo`, params);
}
export function OfficialPersonUpdate (params) {
  return axios.put(clyHR + `OfficialPerson/UpdateInfo`, params);
}
//组织调整
export function OfficialOrgList (params) {
  return axios.get(clyHR + `OfficialOrg/GetInfoPageList`, { params: params });
}
export function OfficialOrgInfo (params) {
  return axios.get(clyHR + `OfficialOrg/GetInfo`, { params: params });
}
export function OfficialOrgAdd (params) {
  return axios.post(clyHR + `OfficialOrg/AddInfo`, params);
}
export function OfficialOrgUpdate (params) {
  return axios.put(clyHR + `OfficialOrg/UpdateInfo`, params);
}

export function PatentGetInfoPageList (params) {
  return axios.get(clyHR + `Patent/GetInfoPageList`, { params: params });
}
export function PatentGetInfoList (params) {
  return axios.get(clyHR + `Patent/GetInfo`, { params: params });
}
export function PatentUpdateInfo (params) {
  return axios.put(clyHR + `Patent/UpdateInfo`, params);
}
export function PatentAddInfo (params) {
  return axios.post(clyHR + `Patent/AddInfo`, params);
}
//获取专利记录
export function getPatentInfoList (params) {
  return axios.get(clyHR + `Patent/GetInfoList`, { params: params });
}

export function ThesisGetInfoPageList (params) {
  return axios.get(clyHR + `Thesis/GetInfoPageList`, { params: params });
}
export function ThesisGetInfoList (params) {
  return axios.get(clyHR + `Thesis/GetInfo`, { params: params });
}
export function ThesisUpdateInfo (params) {
  return axios.put(clyHR + `Thesis/UpdateInfo`, params);
}
export function ThesisAddInfo (params) {
  return axios.post(clyHR + `Thesis/AddInfo`, params);
}
//获取论文记录
export function getThesisInfoList (params) {
  return axios.get(clyHR + `Thesis/GetInfoList`, { params: params });
}

export function RewardsGetSubmitInfoPageList (params) {
  return axios.get(clyHR + `Rewards/GetSubmitInfoPageList`, { params: params });
}
export function RewardsUpdateInfoState (params) {
  return axios.put(clyHR + `Rewards/UpdateInfoState`, params);
}
export function UserUpdateArchiveInfo (params) {
  return axios.put(clyHR + `UserInfo/UpdateArchiveInfo`, params);
}
export function UpdateAuthorizedStrength (params) {
  return axios.put(clyHR + `UserInfo/UpdateAuthorizedStrength`, params);
}
export function UpdatePaymentAccount (params) {
  return axios.post(clyHR + `SalaryReport/UpdatePaymentAccount`, params);
}
export function OfficialOrgGetInfoList (params) {
  return axios.get(clyHR + `OfficialOrg/GetInfoList`, { params: params });
}
export function OfficialPersonGetInfoList (params) {
  return axios.get(clyHR + `OfficialPerson/GetInfoList`, { params: params });
}
export function PositiveGetSubmitInfoPageList (params) {
  return axios.get(clyHR + `Positive/GetSubmitInfoPageList`, {
    params: params,
  });
}
export function PositiveSaveState (params) {
  return axios.get(clyHR + `Positive/SaveState`, { params: params });
}
export function DimissionGetSubmitInfoPageList (params) {
  return axios.get(clyHR + `Dimission/GetSubmitInfoPageList`, {
    params: params,
  });
}
export function DimissionSaveState (params) {
  return axios.get(clyHR + `Dimission/SaveState`, { params: params });
}
export function TransferGetSubmitInfoPageList (params) {
  return axios.get(clyHR + `Transfer/GetSubmitInfoPageList`, {
    params: params,
  });
}
export function TransferSaveState (params) {
  return axios.get(clyHR + `Transfer/SaveState`, { params: params });
}
//添加绩效权限
export function MeritCompetenceAddInfoList (params) {
  return axios.post(clyHR + `MeritCompetence/AddInfoList`, params);
}
//获取指定记录
export function MeritCompetenceGetInfoList (params) {
  return axios.get(clyHR + `MeritCompetence/GetInfoList`, { params: params });
}
//获取绩效月度列表
export function MeritSalaryGetInfoPageList (params) {
  return axios.get(clyHR + `MeritSalary/GetInfoPageList`, { params: params });
}
//添加绩效月度
export function MeritSalaryAddInfo (params) {
  return axios.post(clyHR + `MeritSalary/AddInfo`, params);
}

//导入绩效月度内人员
export function MeritSalaryInsertInfo (params) {
  return axios.post(clyHR + `MeritSalaryList/InsertInfo`, params);
}
//删除绩效月度内人员
export function MeritSalaryListDelInfo (params) {
  return axios.delete(clyHR + `MeritSalaryList/DelInfo`, { params: params });
  //return axios.delete(clyHR + `MeritSalaryList/DelInfo`, { params: params });
}

//删除绩效月度
export function MeritSalaryDelInfo (params) {
  return axios.delete(clyHR + `MeritSalary/DelInfo`, { params: params });
}
//设置绩效月度状态
export function MeritSalarySetInfoSate (params) {
  return axios.put(clyHR + `MeritSalary/SetInfoSate`, params);
}
//获取绩效指定月份的人员记录表
export function MeritSalaryGetPersonHistoryList (params) {
  return axios.get(clyHR + `MeritSalary/GetPersonHistoryList`, { params: params });
}
//获取绩效下所有公司列表
export function MeritSalaryListGetGroupFactoryList (params) {
  return axios.get(clyHR + `MeritSalaryList/GetGroupFactoryList`, { params: params });
}
export function MeritSalaryListGetInfoPageList (params) {
  return axios.get(clyHR + `MeritSalaryList/GetInfoPageList`, { params: params });
}
//绩效月度提交公司
export function MeritSalaryListSubmitInfo (params) {
  return axios.get(clyHR + `MeritSalaryList/SubmitInfo`, { params: params });
}
//绩效月度--修改
export function MeritSalaryListUpdateInfo (params) {
  return axios.put(clyHR + `MeritSalaryList/UpdateInfo`, params);
}
//绩效月度待审核列表
export function MeritSalaryListGetGroupInfoPageList (params) {
  return axios.get(clyHR + `MeritSalaryList/GetGroupInfoPageList`, { params: params });
}
//获取科技成果记录
export function getResearchResultInfoList (params) {
  return axios.get(clyHR + `UserInfo/GetResearchResultList`, { params: params });
}

//添加体检记录
export function AddCheckupInfo (params) {
  return axios.post(clyHR + `Checkup/AddInfo`, params);
}
//获取指定体检记录
export function GetCheckupInfo (params) {
  return axios.get(clyHR + `Checkup/GetInfo`, { params: params });
}
//获取指定人员体检记录
export function GetCheckupInfoList (params) {
  return axios.get(clyHR + `Checkup/GetInfoList`, { params: params });
}
//获取体检记录列表
export function GetCheckupInfoPageList (params) {
  return axios.get(clyHR + `Checkup/GetInfoPageList`, { params: params });
}
//获取体检记录列表
export function UpdateCheckupInfo (params) {
  return axios.put(clyHR + `Checkup/UpdateInfo`, params);
}

//添加培训记录
export function AddTrainInfo(params) {
    return axios.post(clyHR + `Train/AddInfo`, params);
}
//获取指定培训记录
export function GetTrainInfo(params) {
    return axios.get(clyHR + `Train/GetInfo`, { params: params });
}
//获取指定人员培训记录
export function GetTrainInfoList(params) {
    return axios.get(clyHR + `Train/GetInfoList`, { params: params });
}
//获取培训记录列表
export function GetTrainInfoPageListAll(params) {
    return axios.get(clyHR + `Train/GetInfoPageListAll`, { params: params });
}
//获取培训记录列表
export function GetTrainExcelList(params) {
    return axios.get(clyHR + `Train/GetExcelList`, { params: params });
}
//获取培训记录列表
export function GetTrainInfoPageList(params) {
    return axios.get(clyHR + `Train/GetInfoPageList`, { params: params });
}
//更新培训记录列表
export function UpdateTrainInfo(params) {
    return axios.put(clyHR + `Train/UpdateInfo`, params);
}
//删除培训记录
export function DeleteTrainInfo(params) {
    return axios.delete(clyHR + `Train/DelInfo`, { params: params });
}
//获取培训记录列表
export function GetTrainSingInList(params) {
    return axios.get(clyHR + `Train/GetSingInList`, { params: params });
}

//添加岗位职责记录
export function AddPostExplainInfo (params) {
  return axios.post(clyHR + `PostExplain/AddInfo`, params);
}
export function AddInfoForTeam (params) {
  return axios.post(clyHR + `PostExplain/AddInfoForTeam`, params);
}
//获取指定岗位职责记录
export function GetPostExplainInfo (params) {
  return axios.get(clyHR + `PostExplain/GetInfo`, { params: params });
}
//根据岗位ID获取指定岗位职责记录
export function GetInfoByPostId (params) {
  return axios.get(clyHR + `PostExplain/GetInfoByPostId`, { params: params });
}
//获取指定岗位职责记录
export function GetPostExplainInfoList (params) {
  return axios.get(clyHR + `PostExplain/GetInfoList`, { params: params });
}
//获取岗位职责记录列表
export function GetPostExplainInfoPageList (params) {
  return axios.get(clyHR + `PostExplain/GetInfoPageList`, { params: params });
}
//获取岗位职责待确认列表
export function GetPostExplainFormalityPageList (params) {
  return axios.get(clyHR + `PostExplain/GetFormalityPageList`, { params: params });
}
//岗位职责审核
export function GetPostExplainApprovalComplete (params) {
  return axios.get(clyHR + `PostExplain/ApprovalComplete`, { params: params });
}
//获取岗位职责记录列表
export function UpdatePostExplainInfo (params) {
  return axios.put(clyHR + `PostExplain/UpdateInfo`, params);
}
//获取岗位职责记录列表
export function DeletePostExplainInfo (params) {
  return axios.delete(clyHR + `PostExplain/DelInfo`, { params: params });
}

//加密档案-添加记录
export function AddRecordNotesInfo(params) {
    return axios.post(clyHR + `RecordNotes/AddInfo`, params);
}
//加密档案-删除记录
export function DeleteRecordNotesInfo(params) {
    return axios.delete(clyHR + `RecordNotes/DelInfo`, { params: params });
}
//加密档案-获取指定记录
export function GetRecordNotesInfo(params) {
    return axios.get(clyHR + `RecordNotes/GetInfo`, { params: params });
}
//加密档案-获取指定记录列表
export function GetRecordNotesInfoList(params) {
    return axios.get(clyHR + `RecordNotes/GetInfoList`, { params: params });
}
//加密档案-获取分页记录列表
export function GetRecordNotesPageList(params) {
    return axios.get(clyHR + `RecordNotes/GetInfoPageList`, { params: params });
}
//加密档案-更新记录
export function UpdateRecordNotesInfo(params) {
    return axios.put(clyHR + `RecordNotes/UpdateInfo`, params);
}
//获取签到记录列表
export function GetViewSigninInfoPageList(params) {
    return axios.get(clyHR + `ViewSignin/GetInfoPageList`, { params: params });
}

//共享中心-岗位及编制
export function GetSalaPostEstablishmentPartyList(params) {
    return axios.get(clyHR + `SalaPostEstablishment/GetInfoPageList`, { params: params });
}

//福利月度
export function GetBenefitsMonthList(params) {
    return axios.get(clyHR + `BenefitsMonth/GetInfoPageList`, { params: params });
}
export function AddBenefitsMonth(params) {
    return axios.post(clyHR + `BenefitsMonth/AddInfo`, params);
}
export function DeleteBenefitsMonth(params) {
    return axios.delete(clyHR + `BenefitsMonth/DelInfo`, { params: params });
}
export function GetBenefitsReportList(params) {
    return axios.get(clyHR + `BenefitsReport/GetInfoPageList`, { params: params });
}
export function BenefitsMonthGetPersonHistoryList(params) {
    return axios.get(clyHR + `BenefitsMonth/GetPersonHistoryList`, {
        params: params,
    });
}
export function AddBenefitsReport(params) {
    return axios.post(clyHR + `BenefitsReport/AddInfo`, params);
}
export function DelBenefitsReport(params) {
    return axios.delete(clyHR + `BenefitsReport/DelInfo`, { params: params });
}
export function UpdateBenefitsReport(params) {
    return axios.put(clyHR + `BenefitsReport/UpdateInfo`, params);
}
export function AddBenefitsReportExcel(params) {
    return axios.post(clyHR + `BenefitsReport/AddInfoOfExcel`, params);
}

// 文档列表
export function GetDocumentList(params) {
    return axios.get(clyHR + `Document/GetInfoList`, {params: params,});
}
export function GetDocument(params) {
    return axios.get(clyHR + `Document/GetInfo`, { params: params, });
}
// 删除
export function DelDocument(params) {
    return axios.delete(clyHR + `Document/DelInfo`, { params: params });
}
// 设置最新
export function PutDocumentEnabled(params) {
    return axios.delete(clyHR + `Document/SetEnabled`, { params: params, });
}
// 添加数据
export function PostDocumentInfo(params) {
    return axios.post(clyHR + `Document/AddInfo`, params);
}

// 增加访问记录
export function AddVisitUser(params) {
  return axios.post(clyHR + `UserInfo/AddVisitUser`, params);
}