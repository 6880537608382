
/* 产引入jsencrypt实现数据RSA加密 */
import JSEncrypt from 'jsencrypt' // 处理长文本数据时报错 jsencrypt.js Message too long for RSA
/* 产引入encryptlong实现数据RSA加密 */
import Encrypt from 'encryptlong' // encryptlong是基于jsencrypt扩展的长文本分段加解密功能。

// 密钥对生成 http://web.chacuo.net/netrsakeypair

// 公钥key
const publicKey = `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC92kC5QhDTiiIZNgP+JGw0gtJ0
fK+9K9RW3ihWypGHPx+vkhwf/N2H1MeTgSy6IbjdsM6TcPaqwvJ5RkfL1+xqS+ye
obMd5tizwIIzYEdhZgYG9UAUI88PSk7mFyA0wJRJAvz6oGjv7YjNOWJFdhDEp1Xf
f0lljw9P+RX7AMneMQIDAQAB`;
   

// 私钥key
const privateKey = `MIICXAIBAAKBgQC92kC5QhDTiiIZNgP+JGw0gtJ0fK+9K9RW3ihWypGHPx+vkhwf
/N2H1MeTgSy6IbjdsM6TcPaqwvJ5RkfL1+xqS+yeobMd5tizwIIzYEdhZgYG9UAU
I88PSk7mFyA0wJRJAvz6oGjv7YjNOWJFdhDEp1Xff0lljw9P+RX7AMneMQIDAQAB
AoGAcmu5mm2wym10A5HEckTtWfCGemIzYGWsAfa+hSBs+QAYTq5WeQjjUtvwtHuj
w0JTsni+56mrYFtB28l5t+cYuiXu744SYZ9e6N5kavKy2e48B/DDwTmYQ4Sjl0e7
Kv6Pn3SxeL5Kty0qH+m3+w6edVxKCppqhrKOQ97G/7LwBGkCQQDbyCYOxAxUxvvd
nq2k7/aC2MJtliP+s6D2vPWxSUEmhCdZjiMDC4jKfunrhSNBbOMuxqZZahIo5h6n
wPf9evx3AkEA3SN9OJWaKPONdsWLQnc8oSh7u5t1dRcYHmcmVwU5QIaY6p3FU23h
w73UMNCr5C8WTU6PQY2nVqAYuIJnbBmslwJBAJ81vzQFGvWy67NghmOsHUQuJ7Jh
CBQzJdB3k/fonBR9uU+rontLOL2P8H5XhPGZIn25E7pEF4GfKefV5w+trwMCQFV9
etN9QnPCdFYOWKABaLcE55DHsMjrfK+EQRYzNsbzQfqxs69lM3pw0K9ufIVijsSk
Mk2tbZvXmLb78u+fnVECQBxyUGwGWmrc27EO8ICOUMUXNdKv4ZmuQ9q9pg5eaNP0
NJ4R4Hylqr86aJDHIp3ol39i18ynX0mF/UR62r6swKc=`;

export default {
    /* JSEncrypt加密 */
    rsaPublicData(data) {
        var jsencrypt = new JSEncrypt()
        jsencrypt.setPublicKey(publicKey)
        // 如果是对象/数组的话，需要先JSON.stringify转换成字符串
        var result = jsencrypt.encrypt(data)
        return result
    },
    /* JSEncrypt解密 */
    rsaPrivateData(data) {
        var jsencrypt = new JSEncrypt()
        jsencrypt.setPrivateKey(privateKey)
        // 如果是对象/数组的话，需要先JSON.stringify转换成字符串
        var result = jsencrypt.decrypt(data)
        return result
    },
    /* 加密 */
    encrypt(data) {
        const PUBLIC_KEY = publicKey
        var encryptor = new Encrypt()
        encryptor.setPublicKey(PUBLIC_KEY)
        // 如果是对象/数组的话，需要先JSON.stringify转换成字符串
        const result = encryptor.encryptLong(data)
        return result
    },
    /* 解密 - PRIVATE_KEY - 验证 */
    decrypt(data) {
        const PRIVATE_KEY = privateKey
        var encryptor = new Encrypt()
        encryptor.setPrivateKey(PRIVATE_KEY)
        // 如果是对象/数组的话，需要先JSON.stringify转换成字符串
        var result = encryptor.decryptLong(data)
        return result
    }
}