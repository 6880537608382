<template>
  <div>
    <!-- {{username}}
     {{password}} -->
  </div>
</template>

<script>
    import { JSEncrypt } from 'jsencrypt'
    import cryptoAES from '@/../util/cryptoAES'

import Qs from "qs";
import { resetRouter, filterAsyncRouter } from "@/router/index";
import router from "@/router";
import { Log } from '../../../public/oidc-client';

export default {
  name: "loginSingle",
data() {
    return {
      username: this.$route.query.username,
        password: this.$route.query.password,
        mingpassword: "",

        token: this.$route.query.token,
        refresh_token: this.$route.query.refresh_token,
       

    };
  },
  async created () {
   this.login();
//     console.log(this.$route.prams);
//  console.log(this.$route.query);
  },
 methods: {
     async login() {
         this.password = decodeURIComponent(this.password); // 转义
         
         this.mingpassword = this.Rsa.rsaPrivateData(this.password) // 解密
         var data = {
             grant_type: 'password',
             client_id: 'client-grad-pwd',
             client_secret: 'T78JVybD7u2kBcss',
             username: this.$route.query.username,
             password: this.mingpassword,
         };
         //获取token
         //var user = await this.$api.getUserToken(Qs.stringify(data));
         var jwtData = {
             access_token: this.token,
             refresh_token: this.refresh_token,
         };
         //console.log("jwtData", jwtData);
         this.$store.commit("login123", jwtData);
        
         console.log("window.localStorage", window.localStorage);
      //获取菜单
      var _this = this;
      var user = JSON.parse(window.localStorage.user);
      console.log(user.sub)
      console.log(global.appId)
      var loginParams = { uid: user.sub, appId: global.appId };
      var nav = await this.$api.GetModuleNavigationBar(loginParams);
      console.log(nav);
      if (!nav.success) {
        _this.$message({
          message: nav.msg,
          type: "error",
        });
        return false;
      } else {
        window.localStorage.menu = JSON.stringify(nav.response.children);

        let getRouter = nav.response.children; //后台拿到路由
        getRouter = filterAsyncRouter(getRouter); //过滤路由
        router.$addRoutes(getRouter); //动态添加路由

        if (getRouter[0]["type"] == 0) {
          //单页面引用
          this.$router.replace(getRouter[0]["path"]).catch((data) => {});
        } else if (getRouter[0]["type"] == 1) {
          //带导航应用

          this.getNeButtonList(auid, getRouter[0]["id"]);
        } else if (getRouter[0]["type"] == 2) {
          this.$router.push({
            name: "userTemplate",
            query: {
              reportUrl: getRouter[0]["path"],
              reportType: 0,
              t: Date.now(),
            },
          });
        }
      }
      this.$router.replace({
        path: "/",
      });
      this.$message.success("Login Success 登录成功");
      this.islogin = false;
    },
    //获取按钮
    async getNeButtonList(uid, pid) {
      var _this = this;
      var loginParams = { uid: uid, mid: pid };

      await this.$api.GetNeButtonList(loginParams).then((data) => {

        if (!data.success) {
          _this.$message({
            message: data.message,
            type: "error",
          });
          this.$router.push("/refuse");
        } else {
          window.localStorage.buttList = JSON.stringify(data.response);
          this.GetNavigationBarByChilder(uid, pid);
        }
      });
    },
    async GetNavigationBarByChilder(uid, pid) {
      var _this = this;
      var loginParams = { uid: uid, mid: pid };

      await this.$api.GetNewMenuNavigationBar(loginParams).then((data) => {
        console.log(data)
        if (!data.success) {
          _this.$message({
            message: data.message,
            type: "error",
          });
          this.$router.push("/refuse");
        } else {
          let getRouter = data.response.children; //后台拿到路由

          window.localStorage.router = JSON.stringify(data.response.children);
          if (getRouter.length > 0) {
            this.isCollapsdMenu = true;
            this.routes = getRouter;

            getRouter = filterAsyncRouter(getRouter); //过滤路由
            router.$addRoutes(getRouter); //动态添加路由

            if (
              getRouter[0]["children"] != null &&
              getRouter[0]["children"].length > 0
            ) {
              let cPath = this.hasChilden(getRouter[0]["children"][0]);

              _this.$router.replace(cPath).catch((data) => {});
            } else {
              _this.$router.replace(getRouter[0]["path"]).catch((data) => {});
            }
          }
        }
      });
    },
    hasChilden(res) {
      // console.log(res)
      if (res["children"] != null && res["children"].length > 0) {
        this.hasChilden(res["children"][0]);
      } else {
        // console.log(res)
        return res["path"];
      }
    },
  },
};
</script>

<style>
</style>
