import router from '@/router'
import { resetRouter, filterAsyncRouter } from '@/router/index'

import store from "@/store";

// import applicationUserManager from "./Auth/applicationusermanager";

//用来获取后台拿到的路由
var getRouter
if (!getRouter) {//不加这个判断，路由会陷入死循环
    getRouter = getObjArr('router')//拿到路由
    getRouter = filterAsyncRouter(getRouter) //过滤路由
    router.$addRoutes(getRouter) //动态添加路由
    let getMenuRouter = getObjArr('menu')//拿到路由
    getMenuRouter = filterAsyncRouter(getMenuRouter) //过滤路由
    router.$addRoutes(getMenuRouter) //动态添加路由
    global.antRouter = getRouter //将路由数据传递给全局变量，做侧边栏菜单渲染工作
  // }

}

var storeTemp = store;
router.beforeEach((to, from, next) => {
  //验证Token
  {
    
    if (to.meta.requireAuth) {
      if (store.state.isLogin) {  // 判断是否已经登录
        next();
      }
      else {
        next({
          path: '/login',
          query: {redirect: to.fullPath}  // 将跳转的路由path作为参数，登录成功后跳转到该路由
        })
      }
    } else {
      next();
    }
  }

  //动态添加路由
  {
    // //不加这个判断，路由会陷入死循环
    // if (!getRouter) {
    //   if (!getObjArr('router')) {
    //     var user = window.localStorage.user ? JSON.parse(window.localStorage.user) : null;
    //     if (user && user.sub > 0) {
    //       var loginParams = { uid: user.sub };
    //       getNavigationBar(loginParams).then(data => {
    //          // console.log('router before each get navigation bar from api succeed!')
    //         if (data.success) {
    //           getRouter = data.response.children//后台拿到路由
    //           saveObjArr('router', getRouter) //存储路由到localStorage
    //           routerGo(to, next)//执行路由跳转方法
    //         }
    //       });
    //     }
    //   } else {
    //     //从localStorage拿到了路由
    //     getRouter = getObjArr('router')//拿到路由
    //     routerGo(to, next)
    //   }
    // } else {
    //   if (to.name && to.name != 'login') {
    //     getRouter = getObjArr('router')//拿到路由
    //     // console.info(getRouter);
    //     global.antRouter = getRouter
    //     // routerGo(to, next)//执行路由跳转方法
    //   }
    //   next()

    // }
  }
});


function routerGo (to, next) {
  //过滤路由
  getRouter = filterAsyncRouter(getRouter)
  resetRouter()

  //动态添加路由
  router.$addRoutes(getRouter)

  //将路由数据传递给全局变量，做侧边栏菜单渲染工作
  global.antRouter = getRouter
  next({ ...to, replace: true })
}

//localStorage 存储数组对象的方法
function saveObjArr (name, data) {
  localStorage.setItem(name, JSON.stringify(data))
}

//localStorage 获取数组对象的方法
function getObjArr (name) {
  return JSON.parse(window.localStorage.getItem(name));
}


var buttonList = [];
// let  buttons = window.localStorage.buttList? JSON.parse(window.localStorage.buttList)
//              : [];
    // let routers = window.localStorage.router? JSON.parse(window.localStorage.router)
    //          : [];

export const getButtonList = (routePath, routers,buttons) => {
  
  routers.forEach(element => {
    if (routePath && element.path) {
      let path = routePath.toLowerCase();
      if (element.path && element.path.toLowerCase() === path) {
        buttonList = []
        buttons.forEach(elementBu => {
          if (element.id == elementBu.MenuId) { 
                buttonList.push(elementBu)
          }
        });
        return;
      } else if (element.children) {
        getButtonList(path, element.children,buttons);
      }
    }
  });
  return buttonList;
};


