import axios from "./api";

let clyHR = `/hr/`;
let clyOrg = `/cyl/`;
//console.log("process.env.NODE_ENV", process.env.NODE_ENV);
if (process.env.NODE_ENV == "production") { // 生产环境
    //clyHR = `https://api.gradgroup.cn/hr/`;
    //clyOrg = `https://api.gradgroup.cn/`;
    clyHR = `/api/hr/`;
    clyOrg = `/api/`;
}
else if (process.env.NODE_ENV == "development") {// 开发环境
    //  clyHR = `/hr/`;
    //  clyOrg = `/cyl/`;
    clyHR = `https://api.gradgroup.cn/hr/`;
    //clyHR = `http://192.16.81.129:8116/`;
    clyOrg = `https://api.gradgroup.cn/`;
}

  //获取左侧导航菜单
  export const GetModuleNavigationBar = params => {
      return axios.get(clyHR + `Module/GetNavigationByApplicationIdAndUserId`, { params: params }).then(res => res.data);
  };
    //获取导航菜单
  export const GetNewMenuNavigationBar = params => {
      return axios.get(clyHR + `Menu/GetNavigationByModuleIdAndUserId`, { params: params }).then(res => res.data);
  };
  //获取按钮组
  export const GetNeButtonList = params => {
      return axios.get(clyHR + `Button/GetButtonAllByModuleIdAndUserId`, { params: params }).then(res => res.data);
  };
  //用户修改密码
  export const UserModifytPassword = params => {
    return axios.put(`/uc/api/User/ModifyUserPassword`, params);
};
// 增加访问记录
export function AddVisitUser(params) {
    return axios.post(clyHR + `UserInfo/AddVisitUser`, params);
  }
//获取用户token
export function getUserToken(params) {
    return axios.post(`https://api.gradgroup.cn/oauth/token`, params, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8' } });
    //return axios.post(`https://sts.gradgroup.cn/connect/token`, params, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8' } });
    // return axios.post(`/auth/oauth/token`, params,{headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
}