import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'
import './api'//全局引入api
import '../util/global'//全局
import './promissionRouter'//这里进行路由后台获取的模拟
import i18n from './lang' // Internationalization
import Cookies from 'js-cookie'

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import Vant from 'vant';
import 'vant/lib/index.css';
 

import VDistpicker from 'v-distpicker'
import Print from '@/plugins/print'
import '@/assets/iconfont.css'
import '@/assets/css/style.css'
import VueLazyload from 'vue-lazyload'
import signalr from '../util/signalR'
import 'xe-utils'
import VXETable from 'vxe-table'
import Meta from 'vue-meta'
import 'vxe-table/lib/index.css'

import Rsa from "@/../util/rsa.js"
Vue.prototype.Rsa = Rsa

Vue.use(Meta)
Vue.use(VXETable)
// 给 vue 实例挂载全局窗口对象
Vue.prototype.$XModal = VXETable.modal
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: '@/assets/error.png',
  loading: '@/assets/loading.gif',
  attempt: 1
  })
Vue.use(Print)
Vue.component('v-distpicker', VDistpicker)
Vue.use(ElementUI, {
  size: Cookies.get('size') || 'medium', // set element-ui default size
  i18n: (key, value) => i18n.t(key, value)
});
Vue.use(Vant)
Vue.use(signalr)
//注册全局时间过滤器
import util from "../util/date";
Vue.filter('timeConversion', function (date,dateType) {
	
  if (!date) return ''
  return util.formatDate.format(new Date(), dateType);
})
//如果想使用 mock ，开启这两行即可，想看效果，看登录页的 mock登录功能
// import Mock from './mock'
// Mock.bootstrap();

import 'font-awesome/css/font-awesome.min.css'
// import moment from 'moment'//导入文件 
import moment from 'moment-timezone'
  moment.tz.setDefault("Asia/Shanghai");
 Vue.prototype.$moment = moment;//赋值使用

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
router.beforeEach((to, from, next) => {
  store.dispatch('CLEANLOADING')
  next(); //放行
});
