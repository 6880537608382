import axios from "axios";
// import router from '../routerManuaConfig'
import router from "../router/index";
import store from "../store";
import Vue from "vue";
import Qs from "qs";
// import applicationUserManager from "../Auth/applicationusermanager";
let base = "";

// let base1 = 'http://uc.gradgroup.cn';
// 如果是IIS部署，用这个，因为 IIS 只能是 CORS 跨域，不能代理
// let base = process.env.NODE_ENV=="production"? 'http://localhost:8081':'';

// 请求延时
axios.defaults.timeout = 12000000;
function refreshToken() {
    // instance是当前request.js中已创建的axios实例
    const params = {};
    //params.grant_type = 'refresh_token';
    //params.client_id = 'grad_hr_admin';
    //params.refresh_token = store.state.refresh_token;

    params.grant_type = 'refresh_token';
    params.client_id = 'client-grad-pwd';
    params.client_secret = 'T78JVybD7u2kBcss';
 
    params.refresh_token = store.state.refresh_token;

    
    // refreshRequest.post(loginByPass, params, {
    //   header: {
    //     'Content-Type': 'application/x-www-form-urlencoded'
    //   },
    //   custom: {
    //     isCls: 1
    //   }
    return axios.post(`https://api.gradgroup.cn/oauth/token`, Qs.stringify(params),params, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8' } }).then(res => res.data);
    //return axios.post(`https://sts.gradgroup.cn/connect/token`, Qs.stringify(params), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }).then(res => res.data)
}
// 是否正在刷新的标记
let isRefreshing = false
var storeTemp = store;


axios.interceptors.request.use(

    config => {
        // console.log(store.state.access_token)
        // 判断是否存在token，如果存在的话，则每个http header都加上token

        config.headers.Authorization = "Bearer " + localStorage.getItem("access_token");
        //config.Authorization = "Bearer " + localStorage.getItem("access_token");    // 产业链接口调用添加
        //console.log("config.headers.Authorization", "Bearer " + localStorage.getItem("access_token"), config)
        return config;
    },
    err => {
        return Promise.reject(err);
    }
);

// http response 拦截器
axios.interceptors.response.use(

    (response) => {
        // console.log(response);
        // loadingCount--
        // console.log("关闭时的"+loadingCount)
        // if (apiLoading && loadingCount == 0) {
        //        glo_loading.loadingHide()
        // }
        store.dispatch("SetLoding", false);
        return response;
    },
    (error) => {

        store.dispatch("SetLoding", false);
        // apiLoading ? glo_loading.loadingHide() : ''
        // 超时请求处理
        var originalRequest = error.config;

        if (error.response) {


            //401
            if (error.response.status == 401) {
                // store.commit('logout')
                // router.push({ path: '/Login' })
                if (!isRefreshing) {
                    isRefreshing = true
                    return refreshToken().then(res => {
                        store.commit("login", res);
                        // error.
                        error.config.headers.Authorization = "Bearer " + res.access_token;

                        return axios(error.config)
                    }).catch(res => {
                        console.error('refreshtoken error =>', res)
                        store.commit('logout');
                        router.push({ path: '/Login' });
                    }).finally(() => {
                        isRefreshing = false
                    })
                }

            }
        }
        return error.response; // 返回接口返回的错误信息
    }
);

export default axios;
