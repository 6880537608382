import * as hr from './hr';
import * as attendance from './attendance';
import * as oa from './oa';
import * as  common from './public';
import Vue from 'vue'
const api = {
    ...hr,
    ...attendance,
    ...oa,
    ...common
}
Vue.prototype.$api = api
export default api;